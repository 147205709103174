import { useCallback, useEffect, useState } from "react";
import { Payer } from "@oben-core-web/models/payer";
import { PayerService } from "@oben-core-web/services/payer-service";

const usePayers = () => {
  const [payers, setPayers] = useState<Payer[]>([]);

  const fetchPayers = useCallback(async () => {
    const payerService = new PayerService();
    const payerRows = await payerService.getAllPayers();
    setPayers(payerRows);
  }, []);

  useEffect(() => {
    fetchPayers();
  }, [fetchPayers]);

  return { payers, fetchPayers };
};

export default usePayers;
