import usePatients from "@/hooks/usePatients";
import {
  Card,
  HStack,
  VStack,
  Text,
  SimpleGrid,
  GridItem
} from "@chakra-ui/react";
import DashboardStat from "../atoms/DashboardStat";
import PieChart from "../atoms/SexPieChart";
import { groupBy } from "lodash";
import AgeBarChart from "../atoms/AgeBarChart";
import { DateTime } from "luxon";
import usePharmacistQueries from "@/hooks/usePharmacistQueries";
import { LuUsers } from "react-icons/lu";
import RaceBarChart from "../atoms/RaceBarChart";
import { useEffect, useState } from "react";
import { PhysicianQueryService } from "@oben-core-web/services/physician-query-service";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

const ClientDashboardStatistics = () => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { clientUsers } = usePatients(placeBasedCareProvider?.id ?? "");
  const clientUsersBySex = groupBy(clientUsers, "sex");
  const [hasRecommendationCount, setHasRecommendationCount] = useState(0);
  const [needsRecommendationCount, setNeedsRecommendationCount] = useState(0);
  const [enrolledCount, setEnrolledCount] = useState(0);
  const { countOfPatientsAtBPGoal } = usePharmacistQueries(
    placeBasedCareProvider?.id ?? ""
  );
  useEffect(() => {
    const fetchCounts = async () => {
      if (!placeBasedCareProvider?.id) return;
      const hasRecommendation =
        await PhysicianQueryService.getHasRecommendationCount({
          placeBasedCareProvId: placeBasedCareProvider.id
        });
      const needsRecommendation =
        await PhysicianQueryService.getNeedRecommendationCount({
          placeBasedCareProvId: placeBasedCareProvider.id
        });
      const enrollments = await PhysicianQueryService.getEnrolledPatientCount({
        placeBasedCareProvId: placeBasedCareProvider.id
      });
      setHasRecommendationCount(hasRecommendation);
      setNeedsRecommendationCount(needsRecommendation);
      setEnrolledCount(enrollments);
    };
    fetchCounts();
  }, [placeBasedCareProvider]);
  return (
    <HStack w={"full"} spacing={4} className='pharmacist-dashboard-elements'>
      <VStack w={"30%"} spacing={4} h={"full"}>
        <Card
          p={4}
          w={"full"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-start"}
        >
          <VStack w={"full"}>
            <HStack justifyContent={"space-between"} w={"full"}>
              <HStack
                h={"fit-content"}
                mt={1}
                border={"1px solid gray"}
                p={1.5}
                rounded={"xl"}
              >
                <LuUsers size={"1.5em"} />
              </HStack>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"full"}
              >
                <Text maxW={"60%"}>
                  Total number of patients enrolled in program
                </Text>
                <Text fontSize={"x-large"}>{enrolledCount}</Text>
              </HStack>
            </HStack>
            <HStack
              w={"full"}
              justifyContent={"space-between"}
              fontSize={"normal"}
            >
              <Text fontWeight={"normal"} maxW={"60%"}>
                Approved for community health worker benefits
              </Text>
              <Text>{hasRecommendationCount}</Text>
            </HStack>

            <HStack
              w={"full"}
              justifyContent={"space-between"}
              fontSize={"normal"}
            >
              <Text fontWeight={"normal"} maxW={"60%"}>
                Needs recommendation letter
              </Text>
              <Text>{needsRecommendationCount}</Text>
            </HStack>
          </VStack>
        </Card>
        <DashboardStat
          label={"Patients at BP Goal"}
          value={(countOfPatientsAtBPGoal / clientUsers.length) * 100 + "%"}
          description=''
          w={"full"}
        />
      </VStack>
      <HStack w={"70%"} spacing={4} alignItems={"flex-start"} h={"full"}>
        <SimpleGrid columns={13} gap={4}>
          <GridItem colSpan={3}>
            <PieChart
              title={"Sex Distribution"}
              labels={Object.keys(clientUsersBySex)}
              series={Object.values(clientUsersBySex).map((set) => set.length)}
            />
          </GridItem>
          <GridItem colSpan={5}>
            <AgeBarChart
              ages={clientUsers
                .filter((c) => !!c.dob)
                .map((client) => {
                  return Math.floor(
                    -DateTime.fromJSDate(new Date(client.dob!)).diffNow("years")
                      .years
                  );
                })}
            />
          </GridItem>
          <GridItem colSpan={5}>
            <RaceBarChart
              races={clientUsers
                .filter((c) => !!c.race)
                .map((client) => client.race)}
            />
          </GridItem>
        </SimpleGrid>
      </HStack>
    </HStack>
  );
};

export default ClientDashboardStatistics;
