import { DocumentSnapshot } from "firebase/firestore";

import { BpRange, EnrollmentEligibility, ContactMethod, ContactMethodHelper } from "../constants/core-enums";
import { BpReadingService } from "../services/bp-reading-service";
import { ScreeningClientService } from "../services/screening-client-service";
import { BpReading } from "./bp-reading";
// import { PayerSummary } from './payer-summary';
import { UserName } from "./user-name";

export interface IScreeningClientData {
  id: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  dob: Date | null;
  registrationDate: Date | null;
  hypertensionDiagnosis: boolean | null;
  payerId: string | null;
  primaryCareProvId: string | null;
  placeBasedCareProvId: string | null;
  currentBpReading: BpReading | null;
  contactConsent: boolean | null;
  prefContactMethod: ContactMethod | null;
}

export class ScreeningClient {
  id: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  dob: Date | null;
  registrationDate: Date | null;
  hypertensionDiagnosis: boolean | null;
  payerId: string | null;
  primaryCareProvId: string | null;
  placeBasedCareProvId: string | null;
  currentBpReading: BpReading | null;
  contactConsent: boolean | null;
  prefContactMethod: ContactMethod | null;

  constructor({
    id,
    name,
    email,
    phoneNumber,
    dob,
    registrationDate,
    hypertensionDiagnosis,
    payerId,
    primaryCareProvId,
    placeBasedCareProvId,
    currentBpReading,
    contactConsent,
    prefContactMethod,
  }: IScreeningClientData) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.dob = dob;
    this.registrationDate = registrationDate;
    this.hypertensionDiagnosis = hypertensionDiagnosis;
    this.payerId = payerId;
    this.primaryCareProvId = primaryCareProvId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.currentBpReading = currentBpReading;
    this.contactConsent = contactConsent;
    this.prefContactMethod = prefContactMethod;
  }

  // In order to be enrolled in program, client needs to have either:
  // - One VERY HIGH (Stage 2) or CRISIS (Urgent) BP reading
  // - Two consecutive HIGH (Stage 1) BP readings
  // This method checks to see if already Qualified or if they newly qualify based on latest reading.
  async checkEnrollmentEligibility(): Promise<EnrollmentEligibility> {
    const bpReadingDb = new BpReadingService();
    const bpReadings = await bpReadingDb.getClientBpReadings(this.id); // returns a list of all readings, sorted by date (most recent first)
    let subList: BpReading[] = [];
    switch (bpReadings.length) {
      case 0:
        break; // empty list
      case 1:
        subList = [bpReadings[0]];
        break;
      default:
        subList = bpReadings.slice(0, 2); // only look at 2 most recent readings
        break;
    }
    subList.sort((a, b) => a.readingDate.getTime() - b.readingDate.getTime()); // reverse order (to oldest first) for processing
    let setPoint: BpRange = BpRange.Normal;
    let status: EnrollmentEligibility = EnrollmentEligibility.NotQualified; // default
    for (const bpReading of subList) {
      switch (bpReading.range) {
        case BpRange.Crisis:
        case BpRange.VeryHigh: // Stage 2 Hypertension
          status = EnrollmentEligibility.Qualified; // also flags to terminate check loop immediately
          break;
        case BpRange.High: // Stage 1 Hypertension
          if (setPoint === BpRange.High) {
            // current reading is second High in a row
            status = EnrollmentEligibility.Qualified; // also flags to terminate check loop immediately
          } else {
            // current reading is first High in a row
            setPoint = BpRange.High;
            status = EnrollmentEligibility.Pending;
          }
          break;
        case BpRange.Elevated:
        case BpRange.Normal:
        case BpRange.Low:
        default:
          setPoint = bpReading.range; // set to current range (ie. clear "High" if was previously set)
          status = EnrollmentEligibility.NotQualified;
          // continue looping/checking for another qualifying reading
          break;
      }
      if (status === EnrollmentEligibility.Qualified) {
        break; // no need to look further; exit looping
      }
    }
    return status;
  }

  // Delete the user (and all their data) from the Firestore database
  async delete(): Promise<void> {
    const db = new ScreeningClientService();
    await db.deleteScreeningClient(this.id);
  }

  async updateDb(): Promise<void> {
    const db = new ScreeningClientService();
    await db.updateScreeningClient(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): ScreeningClient {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return ScreeningClient.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): ScreeningClient {
    return new ScreeningClient({
      id: id,
      name: UserName.fromMap(data["name"] ?? {}),
      email: data["email"] ?? "",
      phoneNumber: data["phoneNumber"] ?? null,
      dob: data["dob"] // special case for DOB (stored w/o time)
        ? new Date(data["dob"])
        : null,
      registrationDate: data["registrationDate"]
        ? new Date(data["registrationDate"])
        : null,
      hypertensionDiagnosis: data["hypertensionDiagnosis"] ?? null,
      payerId: data["payerId"] ?? null,
      primaryCareProvId: data["primaryCareProvId"] ?? null,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? null,
      currentBpReading: data["currentBpReading"] ?? null,
      contactConsent: data["contactConsent"] ?? null,
      prefContactMethod: ContactMethodHelper.valueOf(data["prefContactMethod"]) ?? ContactMethod.Email,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": id, // not necessary to save ID (it is already part of the Firestore document)
      "name": this.name.toJson(),
      "email": this.email,
      "phoneNumber": this.phoneNumber,
      "dob": this.dob?.toLocaleDateString('en-CA') ?? null, // special case for DOB (stored w/o time)
      "registrationDate": this.registrationDate?.toISOString() ?? null,
      "hypertensionDiagnosis": this.hypertensionDiagnosis,
      "payerId": this.payerId,
      "primaryCareProvId": this.primaryCareProvId,
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "currentBpReading": this.currentBpReading,
      "contactConsent": this.contactConsent,
      "prefContactMethod": this.prefContactMethod,
    };
  }
}
