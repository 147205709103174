import { object, string, ref } from "yup";

const changePasswordSchema = object({
  password: string()
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    )
    .min(12, "Passwords must be at least 12 characters")
    .max(32, "Passwords must be no more than 4096 characters")
    .required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("Password confirmation is required")
});

export default changePasswordSchema;
