import { BarberUserService } from "@oben-core-web/services/barber-user-service";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import { BpReadingService } from "@oben-core-web/services/bp-reading-service";
import { useCallback } from "react";
import { groupBy, reduce } from "lodash";
import { Barbershop } from "@oben-core-web/models/barbershop";
import { BarberUser } from "@oben-core-web/models/barber-user";
import { BillableItemService } from "@oben-core-web/services/billable-item-service";
import { DateTime } from "luxon";
import { BillableItem } from "@oben-core-web/models/billable-item";

export interface IEngagedBarberByCityMetric {
  barbershops: Barbershop[];
  barbers: BarberUser[];
  engagedBarberCount: number;
  totalBarberCount: number;
}

const useProgramManagerQueries = (placeBasedCareProvId: string) => {
  const getBillableItemsByApptStatus = useCallback(async () => {
    if (!placeBasedCareProvId) return;
    const billableItemService = new BillableItemService();
    // get billableItems for this year
    const now = DateTime.now();
    const billableItems = await billableItemService.getAllBillableItems({
      placeBasedCareProvId,
      start: now.set({ year: now.get("year"), month: 0, day: 1 }).toJSDate(),
      end: null
    });
    // map in most recent status
    const billableItemsWithMostRecentStatus = billableItems.map(
      (bi) =>
        ({
          ...bi,
          billStatus: bi.billStatusChanges.toSorted((a, b) =>
            a.date < b.date ? 1 : -1
          )[0]?.status
        } as BillableItem & { billStatus: string })
    );

    // group by paymentStatus
    const groupedBillableItems = groupBy(
      billableItemsWithMostRecentStatus,
      "billStatus"
    );
    // create subgroupings for each appointment status
    const paymentStatusesByApptStatus = reduce(
      groupedBillableItems,
      (a, value, status) => {
        const apptStatusGroups = groupBy(value, (ebi) => {
          return ebi.description.includes("no-show")
            ? "No show"
            : ebi.description.includes("cancelled during")
            ? "No show"
            : ebi.description.includes("cancelled within")
            ? "Cancellation"
            : ebi.description.includes("Appointment started") ||
              ebi.description.includes("Haircut")
            ? "Haircut"
            : ebi.description.includes("CHW")
            ? "CHW Reimbursement"
            : "ERROR";
        });
        const apptStatusesByBillStatus: Record<
          string,
          {
            billableItems: (BillableItem & { billStatus: string })[];
            total: number;
          }
        > = {};
        for (const apptStatus in apptStatusGroups) {
          const apptGroupRow = {
            billableItems: apptStatusGroups[apptStatus],
            total: apptStatusGroups[apptStatus].reduce((a, c) => {
              return a + c.amount;
            }, 0)
          };
          apptStatusesByBillStatus[apptStatus] = apptGroupRow;
        }

        a[status] = apptStatusesByBillStatus;
        return a;
      },
      {} as Record<
        string,
        Record<
          string,
          {
            billableItems: (BillableItem & { billStatus: string })[];
            total: number;
          }
        >
      >
    );
    return paymentStatusesByApptStatus;
  }, [placeBasedCareProvId]);

  const getBarbersByStripeStatus = useCallback(async () => {
    if (!placeBasedCareProvId) return;
    const barberService = new BarberUserService();
    const barbers = await barberService.getBarbersByPlaceBasedCareProvId(
      placeBasedCareProvId
    );
    return groupBy(barbers, "stripeStatus");
  }, [placeBasedCareProvId]);

  const getBarberEngagementByCity = useCallback(async () => {
    if (!placeBasedCareProvId) return;

    const barbershopService = new BarbershopService();
    const barberService = new BarberUserService();
    const bpReadingService = new BpReadingService();
    const barbershops =
      await barbershopService.getPlaceBasedCareProvBarbershops(
        placeBasedCareProvId
      );
    const barbers = await barberService.getBarbersByPlaceBasedCareProvId(
      placeBasedCareProvId
    );
    const barbershopGroups = groupBy(barbershops, "address.city");

    const metricResults = reduce(
      barbershopGroups,
      (a, value, key) => {
        const cityKey = key === "" ? "[No City]" : key;
        if (!a[cityKey]) {
          a[cityKey] = {
            barbershops: [],
            barbers: [],
            engagedBarberCount: 0,
            totalBarberCount: 0
          };
        }
        a[cityKey].barbershops = value;
        return a;
      },
      {} as Record<string, IEngagedBarberByCityMetric>
    );

    for (const city in metricResults) {
      // match barbers to city based on currentBarbershopId
      const barbersInCity = barbers.filter((b) =>
        metricResults[city].barbershops
          .map((bs) => bs.id)
          .includes(b.currentshopId)
      );
      let engagedCount = 0;
      if (barbersInCity.length > 0) {
        // get count of readings by each set of barbers
        engagedCount = await bpReadingService.getBpReadingCountByBarbers({
          barberIds: barbersInCity.map((b) => b.uid)
        });
      }
      // update results
      metricResults[city].barbers = barbersInCity;
      metricResults[city].engagedBarberCount = engagedCount;
      metricResults[city].totalBarberCount = barbersInCity.length;
    }
    return metricResults;
  }, [placeBasedCareProvId]);

  return {
    getBarberEngagementByCity,
    getBarbersByStripeStatus,
    getBillableItemsByApptStatus
  };
};

export default useProgramManagerQueries;
