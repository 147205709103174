import {
  CollectionReference,
  collection,
  DocumentData,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
  QuerySnapshot
} from "firebase/firestore";
import { Database } from "../../firebase";

import { Claim } from "../models/claim";
import { ClaimStatus } from "../models/claim-status-change";
import { GaLog } from "../utils/ga-log";

export class ClaimService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "claims";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of all claims that a client has not yet responded to approving
  streamClientClaims({
    clientId,
    handleSnapshot
  }: {
    clientId: string;
    handleSnapshot: (
      snapshot: QuerySnapshot<DocumentData, DocumentData>
    ) => void;
  }): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;

    const queryFilters = [
      where("clientId", "==", clientId),
      where("currentStatus", "==", ClaimStatus.New),
      orderBy("serviceDate")
    ];
    try {
      const queryRef = query(this._collectionReference, ...queryFilters);
      unsubscribe = onSnapshot(
        queryRef,
        (snapshot) => {
          handleSnapshot(snapshot);
          GaLog.readCollection(
            this._collectionReference.path,
            snapshot.docChanges().length,
            { isSubscription: true }
          );
        },
        (error) => {
          console.error("Error in onSnapshot:", error);
          // TODO: Do we need to throw an error here?
          GaLog.readCollection(this._collectionReference.path, 0, {
            isSubscription: true
          });
        }
      );
    } catch (error) {
      console.log("SOME ERROR HAPPENED");
      GaLog.readError(this._collectionReference.path, error, {
        isSubscription: true
      });
      throw error;
    }

    return { unsubscribe };
  }

  // Returns all claims for a specific client
  async getClientClaims(clientId: string): Promise<Claim[]> {
    const queryRef = query(
      this._collectionReference,
      where("clientId", "==", clientId),
      // by using the following 'where' clause, we can avoid the need for an additional composite index (uses same index as streamClientClaims)
      where("currentStatus", "!=", null), // all claims; since currentStatus is required, this should work
      orderBy("serviceDate")
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) => Claim.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getClaim(claimId: string): Promise<Claim> {
    try {
      const docRef = doc(this._collectionReference, claimId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Claim not found: ${claimId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return Claim.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // When creating a new Claim via a firestore transaction, we need to first (outside of the transaction) create a
  // placeholder document with a unique ID, then update the document with the actual claim data
  // Note that this is not async because it is only creating a placeholder document ID.
  createClaimId(): string {
    try {
      const docRef = doc(this._collectionReference);
      GaLog.addDocument(this._collectionReference.path, docRef.id); // log the new document ID (which is really just a placeholder)
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addClaim(claim: Claim): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, claim.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateClaim(claim: Claim): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, claim.id);
      await updateDoc(docRef, claim.toJson());
      GaLog.updateDocument(this._collectionReference.path, claim.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // not used by web portal
  // async updateClaimTx(claim: Claim, transaction: Transaction): Promise<void> {
  // }

  // // Claims can only be canceled, not deleted
  // async deleteClaim(claimId: string): Promise<void> {}

  //* ===========================================================================
  //* Methods below are custom queries only present on web version of the service
  //* ===========================================================================

  // Get the all claims, with optional filtering by:
  // - clientId (defaults to all clientIds)
  // - start (defaults to now)
  // - end (defaults to 30 days before today at 00:00)
  // - currentStatus (defaults to all statuses)
  async getAllClientClaimsFiltered({
    clientId,
    placeBasedCareProvId,
    start,
    end,
    statuses
  }: {
    clientId?: string | null;
    placeBasedCareProvId?: string | null;
    start: Date | null;
    end: Date | null;
    statuses: ClaimStatus[];
  }): Promise<Claim[]> {
    const now = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);
    const startOfRange = start || thirtyDaysAgo;
    const endOfRange = end || now;
    const filters = [
      where("serviceDate", ">=", startOfRange.toISOString()),
      where("serviceDate", "<", endOfRange.toISOString()),
      orderBy("serviceDate")
    ];
    if (clientId) {
      filters.push(where("clientId", "==", clientId));
    }
    if (placeBasedCareProvId) {
      filters.push(where("placeBasedCareProvId", "==", placeBasedCareProvId));
    }
    if (statuses.length > 0) {
      filters.push(where("currentStatus", "in", statuses));
    }
    try {
      const queryRef = query(this._collectionReference, ...filters);
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) => Claim.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }
}
