import { UserType, UserTypeHelper } from '../constants/core-enums';

export enum ClaimStatus {
  Unknown = 'Unknown',
  New = 'New',
  RejectedByPatient = 'RejectedByPatient',
  ApprovedByPatient = 'ApprovedByPatient',
  RejectedByPBCP = 'RejectedByPBCP',
  ApprovedByPBCP = 'ApprovedByPBCP',
  NeedsFurtherReview = 'NeedsFurtherReview',
  Submitted = 'Submitted',
  Denied = 'Denied',
  ClaimPaidByInsurer = 'ClaimPaidByInsurer',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class ClaimStatusHelper {
  static valueOf(searchString: string | null): ClaimStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(ClaimStatus).includes(searchString as ClaimStatus)) //
      ? searchString as ClaimStatus
      : null;
  }
}

export interface IClaimStatusChangeData {
  status: ClaimStatus;
  date: Date;
  editorType: UserType;
  editorId: string;
  details: string;
}

// ClaimStatusChange records are maintained in a list on Appointment
export class ClaimStatusChange {
  status: ClaimStatus;
  date: Date;
  editorType: UserType;
  editorId: string;
  details: string;

  constructor({ 
    status, 
    date, 
    editorType, 
    editorId, 
    details,
  }: IClaimStatusChangeData) {
    this.status = status;
    this.date = date;
    this.editorType = editorType;
    this.editorId = editorId;
    this.details = details;
  }

  static fromMap(data: { [key: string]: any }): ClaimStatusChange {
    return new ClaimStatusChange({
      status: ClaimStatusHelper.valueOf(data["status"] ?? "") ?? ClaimStatus.Unknown,
      date: data["date"] ? new Date(data["date"]) : new Date(1, 0, 1), // default to 1/1/0001
      editorType: UserTypeHelper.valueOf(data["editorType"] ?? "") ?? UserType.Unknown,
      editorId: data["editorId"] ?? "",
      details: data["details"] ?? "",
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "status": this.status,
      "date": this.date.toISOString(),
      "editorType": this.editorType,
      "editorId": this.editorId,
      "details": this.details,
    };
  }
}