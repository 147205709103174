import { ClientType } from "@oben-core-web/constants/core-enums";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ScreeningClient } from "@oben-core-web/models/screening-client";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import { ScreeningClientService } from "@oben-core-web/services/screening-client-service";
import { useCallback, useEffect, useRef, useState } from "react";

const useEnrollmentPatient = ({
  patientId,
  patientType,
  fetchOnMount = false
}: {
  patientId: string;
  patientType: ClientType;
  fetchOnMount?: boolean;
}) => {
  const [enrollmentPatient, setEnrollmentPatient] = useState<
    ClientUser | ScreeningClient
  >();
  const wasFetched = useRef<boolean>(false);
  const fetchEnrollmentPatient = useCallback(async () => {
    let patient: ClientUser | ScreeningClient;
    console.log("FETCH");
    if (patientType === ClientType.ScreeningClient) {
      const screeningClientService = new ScreeningClientService();
      patient = await screeningClientService.getScreeningClient(patientId);
      setEnrollmentPatient(patient);
      return patient;
    } else {
      const clientUserService = new ClientUserService();
      patient = await clientUserService.getClientUser(patientId);
      setEnrollmentPatient(patient);
      return patient;
    }
  }, [patientId, patientType]);

  useEffect(() => {
    if (fetchOnMount && !wasFetched.current) {
      wasFetched.current = true;
      fetchEnrollmentPatient();
    }
  }, [fetchEnrollmentPatient, fetchOnMount, wasFetched]);

  return { enrollmentPatient, fetchEnrollmentPatient };
};

export default useEnrollmentPatient;
