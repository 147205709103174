import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { Database } from "../../firebase";

import { InternalTask } from "../models/internal-task";
import { GaLog } from "../utils/ga-log";

export class InternalTaskService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "internalTasks";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // Get all tasks for a particular assignee (staff member)
  async getAssigneeTasks(assigneeId: string): Promise<InternalTask[]> {
    const queryRef = query(
      this._collectionReference,
      where("assigneeId", "==", assigneeId),
      orderBy("createdDate", "desc")
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        InternalTask.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getTask(internalTaskId: string): Promise<InternalTask> {
    try {
      const docRef = doc(this._collectionReference, internalTaskId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`InternalTask not found: ${internalTaskId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return InternalTask.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addTask(internalTask: InternalTask): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, internalTask.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateTask(internalTask: InternalTask): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, internalTask.id);
      await updateDoc(docRef, internalTask.toJson());
      GaLog.updateDocument(this._collectionReference.path, internalTask.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  async deleteTask(internalTask: InternalTask): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, internalTask.id);
      await deleteDoc(docRef);
      GaLog.deleteDocument(this._collectionReference.path, internalTask.id);
      return;
    } catch (error) {
      GaLog.deleteError(this._collectionReference.path, error);
      throw error;
    }
  }
}
