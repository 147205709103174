import { PlaceBasedCareProv } from "@oben-core-web/models/place-based-care-prov";
import { WebUser } from "@oben-core-web/models/web-user";

// Define action types
type TAuthAction =
  | { type: "ON_LOGIN"; payload: { currentUser: WebUser } }
  | {
      type: "SET_PLACE_BASED_CARE_PROVIDER";
      payload: { placeBasedCareProvider: PlaceBasedCareProv };
    }
  | { type: "BEFORE_LOGOUT" }
  | { type: "SET_FIRST_TIME_LOGIN"; payload: { isFirstTimeLogin: boolean } }
  | {
      type: "SET_DETACH_LISTENER";
      payload: { detachAuthListener: () => void };
    };

// Define the Auth state structure
export interface IAuthState {
  currentUser: WebUser | null;
  placeBasedCareProvider: PlaceBasedCareProv | null;
  detachAuthListener: (() => void) | undefined;
  isLoggedIn: boolean;
  isFirstTimeLogin: boolean;
}

// Initial state
export const initialState: IAuthState = {
  currentUser: null,
  placeBasedCareProvider: null,
  detachAuthListener: undefined,
  isLoggedIn: false,
  isFirstTimeLogin: true
};

// Reducer function
export const authReducer = (
  state: IAuthState,
  action: TAuthAction
): IAuthState => {
  switch (action.type) {
    case "ON_LOGIN":
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isLoggedIn: true
      };
    case "SET_PLACE_BASED_CARE_PROVIDER":
      return {
        ...state,
        placeBasedCareProvider: action.payload.placeBasedCareProvider
      };
    case "SET_FIRST_TIME_LOGIN":
      return {
        ...state,
        isFirstTimeLogin: action.payload.isFirstTimeLogin
      };
    case "BEFORE_LOGOUT":
      // Optionally call the detachAuthListener before logging out
      state.detachAuthListener?.();
      return initialState;
    case "SET_DETACH_LISTENER":
      return {
        ...state,
        detachAuthListener: action.payload.detachAuthListener
      };
    default:
      return state;
  }
};
