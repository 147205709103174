import useAppointments from "@/hooks/useAppointments";
import { ColDef } from "ag-grid-community";
import { UserType } from "@oben-core-web/constants/core-enums";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { Appointment } from "@oben-core-web/models/appointment";
import { HStack, IconButton, Tooltip, VStack } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { PhoneIcon } from "@chakra-ui/icons";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

interface IApptConfirmationTable {
  setConfirmationAppt: (appt: Appointment) => void;
}

const ApptConfirmationTable = ({
  setConfirmationAppt
}: IApptConfirmationTable) => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const [webUserIds, setWebUserIds] = useState<string>();
  const [start] = useState(DateTime.now().startOf("day").toJSDate());
  const [end] = useState(
    DateTime.now().startOf("day").plus({ days: 30 }).toJSDate()
  );
  const { appointments } = useAppointments({
    pharmacistId: webUserIds,
    start,
    end,
    statuses: [AppointmentStatus.New]
  });
  useEffect(() => {
    const fetchWebUserIds = async () => {
      const webUserService = new WebUserService();
      const webUsers = await webUserService.getPlaceBasedCareProvWebUsers(
        placeBasedCareProvider?.id ?? ""
      );
      setWebUserIds(
        webUsers
          .filter(
            (wu) =>
              wu.userType === UserType.Pharmacist ||
              wu.userType === UserType.Physician
          )
          .map((u) => u.uid)
          .join(",")
      );
    };
    placeBasedCareProvider?.id && fetchWebUserIds();
  }, [placeBasedCareProvider?.id]);
  const unconfirmedAppts = useMemo(() => {
    return appointments.filter((a) =>
      a.currentStatus
        ? a.currentStatus === AppointmentStatus.New
        : a.statusChanges[a.statusChanges.length - 1]?.status ===
          AppointmentStatus.New
    );
  }, [appointments]);
  const columnHeaders: ColDef<Appointment & { actions: any }>[] = useMemo(
    () => [
      { field: "clientName.display", headerName: "Patient", flex: 1 },
      {
        field: "date",
        headerName: "Date",
        valueFormatter: (row) =>
          row.data?.date
            ? DateTime.fromJSDate(row.data.date).toFormat("DD 'at' hh:mma")
            : "00/00/00",
        flex: 0.5
      },
      // {
      //   field: "date",
      //   headerName: "Time",
      //   valueFormatter: (row) =>
      //     row.data?.date
      //       ? DateTime.fromJSDate(row.data?.date).toFormat("hh:mma")
      //       : "00:00"
      // },
      { field: "barberName.display", headerName: "Barber", flex: 1 },
      { field: "barbershopName", headerName: "Barbershop", flex: 1 },
      { field: "pharmacistName.display", headerName: "Pharmacist", flex: 1 },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        cellRenderer: (row: any) => {
          return (
            <HStack alignItems={"center"} h={"full"}>
              <Tooltip label={"Confirm Appointment"}>
                <IconButton
                  variant={"link"}
                  aria-label={`complete-task-btn-${row.data.id}`}
                  icon={<PhoneIcon />}
                  onClick={() => {
                    setConfirmationAppt(row.data);
                  }}
                  // onClick={async () => {
                  //   navigate("/appointments", {
                  //     state: {
                  //       from: "/?page=apptConfirmation",
                  //       clientId: row.data?.clientId ?? "NO ID",
                  //       clientName: row.data?.client.name,
                  //       prefBarber: row.data?.prefBarber,
                  //       prefBarbershop: row.data?.prefBarbershop
                  //     },
                  //     replace: true
                  //   });
                  // }}
                />
              </Tooltip>
            </HStack>
          );
        },
        flex: 0.5
      }
    ],
    [setConfirmationAppt]
  );
  return (
    <VStack p={2} h={"full"} w={"full"}>
      <div
        className='ag-theme-quartz'
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          rowData={unconfirmedAppts}
          columnDefs={columnHeaders as any}
        />
      </div>
    </VStack>
  );
};

export default ApptConfirmationTable;
