import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { Payer } from '../models/payer';
import { GaLog } from '../utils/ga-log';

export class PayerService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'payers';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // TODO: include filter on ProgramId or some other identifier to limit visibility into other programs
  async getAllPayers(): Promise<Payer[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => Payer.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a Payer exists
  async validatePayer(payerId: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, payerId);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPayer(payerId: string): Promise<Payer> {
    try {
      const docRef = doc(this._collectionReference, payerId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Payer not found: ${payerId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return Payer.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addPayer(payer: Payer): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, payer.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updatePayer(payer: Payer): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, payer.id);
      await updateDoc(docRef, payer.toJson());
      GaLog.updateDocument(this._collectionReference.path, payer.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // Payers can only be updated, not deleted
  // async deletePayer(payerId: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, payerId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, payerId);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
