import { Color, Colors } from './color';

export enum Flavor {
  DEV,
  ALPHA,
  BETA,
  PROD,
}

export enum WellnessCategory {
  Exercise = 'Exercise',
  Nutrition = 'Nutrition',
  Stress = 'Stress',
}

export enum ResponseType {
  MythOrFact = 'MythOrFact',
  MultiSelection = 'MultiSelection',
  SingleSelection = 'SingleSelection',
  TextInput = 'TextInput',
}

export enum StepType {
  Spacer = 'Spacer',
  Question = 'Question',
  Dyk = 'Dyk',
  Content = 'Content',
}

export enum ContentType {
  Audio = 'Audio',
  Image = 'Image',
  Markdown = 'Markdown',
  Video = 'Video',
}

export enum NetworkAssetItem {
  WelcomeMessage = 'WelcomeMessage',
  BpGuide = 'BpGuide',
  PatientAppDownloadQRCode = 'PatientAppDownloadQRCode',
}

export enum BpRange {
  Crisis = 'Crisis',
  VeryHigh = 'VeryHigh',
  High = 'High',
  Elevated = 'Elevated',
  Normal = 'Normal',
  Low = 'Low',
  Error = 'Error',
}

interface IBpRangeData {
  upperLimitSys: number;
  lowerLimitSys: number;
  upperLimitDia: number;
  lowerLimitDia: number;
  requiresBothInRange: boolean;
  label: string;
  stage: string;
  textColor: Color;
  backgroundColor: Color;
  foregroundColor: Color;
}
export const bpRangeData: Record<BpRange, IBpRangeData> = {
  [BpRange.Crisis]: {
    upperLimitSys: 500,
    lowerLimitSys: 181,
    upperLimitDia: 500,
    lowerLimitDia: 121,
    requiresBothInRange: false,
    label: 'label_crisis',
    stage: 'label_stage_crisis',
    textColor: new Color(0xFFD500F9), // purpleAccent.shade400,
    backgroundColor: new Color(0xFFEA80FC), // purpleAccent.shade100,
    foregroundColor: Colors.white,
  },
  [BpRange.VeryHigh]: {
    upperLimitSys: 180,
    lowerLimitSys: 140,
    upperLimitDia: 120,
    lowerLimitDia: 90,
    requiresBothInRange: false,
    label: 'label_very_high',
    stage: 'label_stage_2',
    textColor: new Color(0xFFF44336), // red.shade500,
    backgroundColor: new Color(0xFFEF9A9A), // red.shade200,
    foregroundColor: Colors.black,
  },
  [BpRange.High]: {
    upperLimitSys: 139,
    lowerLimitSys: 130,
    upperLimitDia: 89,
    lowerLimitDia: 80,
    requiresBothInRange: false,
    label: 'label_high',
    stage: 'label_stage_1',
    textColor: new Color(0xFFFF9800), // orange.shade500,
    backgroundColor: new Color(0xFFFFCC80), // orange.shade200,
    foregroundColor: Colors.black,
  },
  [BpRange.Elevated]: {
    upperLimitSys: 129,
    lowerLimitSys: 120,
    upperLimitDia: 79,
    lowerLimitDia: 60,
    requiresBothInRange: true,
    label: 'label_elevated',
    stage: 'label_stage_elevated',
    textColor: new Color(0xFFFDD835), // yellow.shade600,
    backgroundColor: new Color(0xFFFFF9C4), // yellow.shade100,
    foregroundColor: Colors.black,
  },
  [BpRange.Normal]: {
    upperLimitSys: 119,
    lowerLimitSys: 90,
    upperLimitDia: 79,
    lowerLimitDia: 60,
    requiresBothInRange: true,
    label: 'label_normal',
    stage: 'label_stage_normal',
    textColor: new Color(0xFF4CAF50), // green.shade500,
    backgroundColor: new Color(0xFFC8E6C9), // green.shade100,
    foregroundColor: Colors.black,
  },
  [BpRange.Low]: {
    upperLimitSys: 89,
    lowerLimitSys: 70,
    upperLimitDia: 59,
    lowerLimitDia: 40,
    requiresBothInRange: false,
    label: 'label_low',
    stage: 'label_stage_low',
    textColor: new Color(0xFF2196F3), // blue.shade500,
    backgroundColor: new Color(0xFFBBDE97), // blue.shade100,
    foregroundColor: Colors.black,
  },
  [BpRange.Error]: {
    upperLimitSys: 69,
    lowerLimitSys: 0,
    upperLimitDia: 39,
    lowerLimitDia: 0,
    requiresBothInRange: false,
    label: 'label_error',
    stage: 'label_error',
    textColor: Colors.black,
    backgroundColor: Colors.black,
    foregroundColor: Colors.white,
  },
};

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class BpRangeHelper {
  static valueOf(searchString: string | null): BpRange | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(BpRange).includes(searchString as BpRange)) //
      ? searchString as BpRange
      : null;
  }
  
  static lookup(systolic: number | null, diastolic: number | null): BpRange | null {
    if ((systolic === null) || (diastolic === null)) {
      return null;
    }
    return Object.values(BpRange).find((range) => {
      const data = bpRangeData[range as BpRange];
      if (data.requiresBothInRange) {
        return (
          (systolic <= data.upperLimitSys && systolic >= data.lowerLimitSys) &&
          (diastolic <= data.upperLimitDia && diastolic >= data.lowerLimitDia)
        );
      } else {
        return (
          (systolic <= data.upperLimitSys && systolic >= data.lowerLimitSys) ||
          (diastolic <= data.upperLimitDia && diastolic >= data.lowerLimitDia)
        );
      }
    }) as BpRange | null;
  }
}

export enum UserType {
  Unknown = 'Unknown',
  Client = 'Client',
  Barber = 'Barber',
  Pharmacist = 'Pharmacist',
  ProgramManager = 'ProgramManager',
  Physician = 'Physician',
  ObenAdmin = 'ObenAdmin',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class UserTypeHelper {
  static valueOf(searchString: string | null): UserType | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(UserType).includes(searchString as UserType)) //
      ? searchString as UserType
      : null;
  }
}

export enum FreqPeriod {
  Unknown = 'Unknown',
  Once = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class FreqPeriodHelper {
  static valueOf(searchString: string | null): FreqPeriod | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(FreqPeriod).includes(searchString as FreqPeriod)) //
      ? searchString as FreqPeriod
      : null;
  }
}

export enum EnrollmentEligibility {
  Qualified = 'Qualified',
  Pending = 'Pending',
  NotQualified = 'NotQualified',
}


export enum Sex {
  Unknown = "Unknown",
  Male = "Male",
  Female = "Female",
  Intersex = "Intersex",
}

interface ISexData {
  label: string;
  color: Color;
  icon: string;  
}

export const sexData: Record<Sex, ISexData> = {
  [Sex.Unknown]: {
    label: 'sex_unknown',
    color: new Color(0xFF9E9E9E), // grey
    icon: 'faQuestion',
  },
  [Sex.Male]: {
    label: 'sex_male',
    color: new Color(0xFF1565C0), // blue
    icon: 'faMars',
  },
  [Sex.Female]: {
    label: 'sex_female',
    color: new Color(0xFFD81B60), // pink
    icon: 'faVenus',
  },
  [Sex.Intersex]: {
    label: 'sex_intersex',
    color: new Color(0xFF7B1FA2), // purple
    icon: 'faMarsAndVenus',
  },
};

export class SexHelper {
  static valueOf(searchString: string | null): Sex | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(Sex).includes(searchString as Sex)) //
      ? searchString as Sex
      : null;
  }
}

export enum Race {
  Unknown = "Unknown",
  Black = "Black",
  White = "White",
  Other = "Other",
}

interface IRaceData {
  label: string;
}

export const raceData: Record<Race, IRaceData> = {
  [Race.Unknown]: {
    label: 'race_unknown',
  },
  [Race.Black]: {
    label: 'race_black',
  },
  [Race.White]: {
    label: 'race_white',
  },
  [Race.Other]: {
    label: 'race_other',
  },
}

export class RaceHelper {
  static valueOf(searchString: string | null): Race | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(Race).includes(searchString as Race)) //
      ? searchString as Race
      : null;
  }
}

export enum ContactMethod {
  Email = "Email",
  Phone = "Phone",
  SMS = "SMS",
}

export class ContactMethodHelper {
  static valueOf(searchString: string | null): ContactMethod | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(ContactMethod).includes(searchString as ContactMethod)) //
      ? searchString as ContactMethod
      : null;
  }
}

export enum ClientType {
  ScreeningClient = "ScreeningClient",
  ClientUser = "ClientUser",
}

export class ClientTypeHelper {
  static valueOf(searchString: string | null): ClientType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(ClientType).includes(searchString as ClientType) //
      ? (searchString as ClientType)
      : null;
  }
}

export enum SourceForCHWFunds {
  None = "None",
  Payer = "Payer", // Insurance (they may fund either via Medicaid program or directly from Insurance co.)
  // PlaceBasedCareProv = "PlaceBasedCareProv", // Not Insurance (directly from allocated funds at PBCP, which can come from a variety of sources, eg. grants, Health System, FQHC, etc.)
}

export class SourceForCHWFundsHelper {
  static valueOf(searchString: string | null): SourceForCHWFunds | null {
    if (searchString == null) {
      return null;
    }
    return Object.values(SourceForCHWFunds).includes(searchString as SourceForCHWFunds) //
      ? (searchString as SourceForCHWFunds)
      : null;
  }
}
