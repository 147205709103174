import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import RootRouter from "./routers/index";
import { AuthProvider } from "./context/AuthContext";
import { AppProvider } from "./context/AppContext";
import { TourProvider } from "./context/TourContext";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider
    // theme={theme}
    >
      <AuthProvider>
        <AppProvider>
          <TourProvider>
            <RootRouter />
          </TourProvider>
        </AppProvider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);
