import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  where,
  query
} from "firebase/firestore";
import { Database } from "../../firebase";

import { ScreeningClient } from "../models/screening-client";
import { GaLog } from "../utils/ga-log";

export class ScreeningClientService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "screeningClients";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // TODO: include filter on ProgramId or some other identifier so that teams don't have visibility into staff in other programs
  async getAllScreeningClients(): Promise<ScreeningClient[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        ScreeningClient.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getScreeningClient(id: string): Promise<ScreeningClient> {
    try {
      const docRef = doc(this._collectionReference, id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`ScreeningClient not found: ${id}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return ScreeningClient.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addScreeningClient(screeningClient: ScreeningClient): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        screeningClient.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateScreeningClient(screeningClient: ScreeningClient): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, screeningClient.id);
      await updateDoc(docRef, screeningClient.toJson());
      GaLog.updateDocument(this._collectionReference.path, screeningClient.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  async deleteScreeningClient(id: string): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, id);
      await deleteDoc(docRef);
      GaLog.deleteDocument(this._collectionReference.path, id);
      return;
    } catch (error) {
      GaLog.deleteError(this._collectionReference.path, error);
      throw error;
    }
  }

  /**
   * WEB ONLY FUNCTIONS
   *
   */

  async getScreeningClientsByPlaceBasedCareProvId(
    placeBasedCareProvId: string
  ): Promise<ScreeningClient[]> {
    try {
      const screeningClientQuery = query(
        this._collectionReference,
        where("placeBasedCareProvId", "==", placeBasedCareProvId)
      );
      const qSnapshot = await getDocs(screeningClientQuery);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        ScreeningClient.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // not used by web portal
  // async lookupByNameDob(
  //   lastName: string,
  //   dob: Date
  // ): Promise<ScreeningClient[] | any> {
  // }
}
