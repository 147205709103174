import { BillStatus } from "@oben-core-web/models/bill-status-change";
import { BillableEvent } from "@oben-core-web/models/billable-event";
import { BillableItem } from "@oben-core-web/models/billable-item";
import { BillableEventService } from "@oben-core-web/services/billable-event-service";
import { BillableItemService } from "@oben-core-web/services/billable-item-service";
import { useCallback, useEffect, useRef, useState } from "react";

const useBillableEvent = (billableEventId: string) => {
  const wasFetched = useRef(false);
  const [billableData, setBillableData] = useState<{
    billableEvent: BillableEvent;
    billableItems: BillableItem[];
    isSubmitted: boolean;
  }>();
  const refetch = useCallback(async (billableEventId: string) => {
    const billableEventService = new BillableEventService();
    const billableItemService = new BillableItemService();
    const billableEvent = await billableEventService.getBillableEvent(
      billableEventId
    );
    const billableItems = await billableItemService.getEventBillableItems(
      billableEventId
    );
    setBillableData({
      billableEvent,
      billableItems,
      isSubmitted: billableItems.every((item) => {
        const billStatuses = item.billStatusChanges.map((s) => s.status);
        return (
          billStatuses.includes(BillStatus.ApprovedForSubmission) ||
          billStatuses.includes(BillStatus.Submitted) ||
          billStatuses.includes(BillStatus.Paid)
        );
      })
    });
  }, []);
  useEffect(() => {
    if (!wasFetched.current && billableEventId) {
      refetch(billableEventId).then(() => {
        wasFetched.current = true;
      });
    }
  }, [billableEventId, refetch]);
  return { billableData, refetch };
};

export default useBillableEvent;
