import { UserType } from "@oben-core-web/constants/core-enums";
import { ClaimStatus } from "@oben-core-web/models/claim-status-change";
import { object, string, date } from "yup";

const claimStatusSchema = object({
  date: date(),
  editorId: string().required("Editor is required"),
  editorType: string()
    .oneOf([
      UserType.Pharmacist,
      UserType.Physician,
      UserType.ProgramManager,
      UserType.ObenAdmin
    ])
    .required("Editor type is required"),
  details: string().required(
    "You must include a description of this status change"
  ),
  status: string()
    .oneOf(
      [
        ClaimStatus.ApprovedByPBCP,
        ClaimStatus.NeedsFurtherReview,
        ClaimStatus.New,
        ClaimStatus.RejectedByPBCP,
        ClaimStatus.Submitted
      ],
      "Invalid Claim Status"
    )
    .required("Claim status is required")
});

export default claimStatusSchema;
