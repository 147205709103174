import { useMemo } from "react";
import { useAuthContext } from "./useAuthContext";

const usePlaceBasedCareProvider = () => {
  const {
    state: { placeBasedCareProvider }
  } = useAuthContext();

  return useMemo(() => ({ placeBasedCareProvider }), [placeBasedCareProvider]);
};

export default usePlaceBasedCareProvider;
