import React from "react";
import { Center, Text, VStack } from "@chakra-ui/react";
import useCurrentUser from "@/hooks/useCurrentUser";
import ProgramManagerDashboard from "../organisms/ProgramManagerDashboard";
import { UserType } from "@oben-core-web/constants/core-enums";
import PhysicianDashboard from "../organisms/PhysicianDashboard";
import UpcomingAppointments from "../molecules/UpcomingAppointmentsTable";
import ClientDashboardStatistics from "../organisms/ClientDashboardStatistics";

const Home = () => {
  const { currentUser } = useCurrentUser();
  return (
    <VStack w={"full"} h={"full"} alignItems={"flex-start"}>
      <Text as={"h1"} fontSize={"xx-large"}>
        Welcome {currentUser?.name.first}!
      </Text>
      <Center
        h={"full"}
        w={"full"}
        rounded={"md"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
      >
        {currentUser?.userType === UserType.ProgramManager && (
          <ProgramManagerDashboard />
        )}
        {currentUser?.userType === UserType.Physician && <PhysicianDashboard />}
        {currentUser?.userType === UserType.Pharmacist && (
          <VStack w={"full"} h={"full"}>
            <ClientDashboardStatistics />
            <UpcomingAppointments h={"50%"} />
          </VStack>
        )}
      </Center>
    </VStack>
  );
};

export default Home;
