import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  BoxProps
} from "@chakra-ui/react";
import React from "react";

interface IDashboardStat extends BoxProps {
  label?: string;
  value?: string | number;
  description?: string;
  arrow?: "increase" | "decrease";
}

const DashboardStat = ({
  label = "",
  value = "",
  arrow,
  description = "",
  ...rest
}: IDashboardStat) => {
  return (
    <Stat bg={"white"} rounded={"lg"} p={5} {...rest}>
      <StatLabel>{label}</StatLabel>
      <StatNumber>{value}</StatNumber>
      <StatHelpText>
        {arrow && <StatArrow type={arrow} />}
        {description}
      </StatHelpText>
    </Stat>
  );
};

export default DashboardStat;
