import { Icon } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { Link, useLocation } from "react-router-dom";

interface INavLink {
  to: string;
  icon: IconType;
  label: string;
  className?: string;
}

const NavLink = ({ to, icon, label, className }: INavLink) => {
  const location = useLocation();
  return (
    <Link
      to={to}
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        // paddingLeft: 16,
        // paddingRight: 16,
        padding: "8px 16px 8px 16px",
        backgroundColor: location.pathname === to ? "#EDF2F7" : "white",
        height: "2.5rem"
      }}
    >
      <Icon as={icon} mr={2} strokeWidth={3} /> {label}
    </Link>
  );
};

export default NavLink;
