import { DocumentSnapshot } from "firebase/firestore";

import { ClaimBaseService } from "../services/claim-base-service";

export interface IClaimBaseData {
  id: string;
  placeBasedCareProvId: string;
  payerId: string | null;
  serviceDuration: number;
  serviceUnitCount: number;
  serviceCostPerUnit: number;
  billingCode: string;
  billingModifier: string;
  description: string;
  enabled: boolean;
}

export class ClaimBase {
  id: string;
  placeBasedCareProvId: string;
  payerId: string | null;
  serviceDuration: number;
  serviceUnitCount: number;
  serviceCostPerUnit: number;
  billingCode: string;
  billingModifier: string;
  description: string;
  enabled: boolean;

  constructor({
    id,
    placeBasedCareProvId,
    payerId,
    serviceDuration,
    serviceUnitCount,
    serviceCostPerUnit,
    billingCode,
    billingModifier,
    description,
    enabled,
  }: IClaimBaseData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.payerId = payerId;
    this.serviceDuration = serviceDuration;
    this.serviceUnitCount = serviceUnitCount;
    this.serviceCostPerUnit = serviceCostPerUnit;
    this.billingCode = billingCode;
    this.billingModifier = billingModifier
    this.description = description;
    this.enabled = enabled;
  }

  //
  // Getters
  //
  get amount(): number {
    return (this.serviceUnitCount * this.serviceCostPerUnit); // in cents (e.g. 1250 = $12.50)
  }

  async updateDb(): Promise<void> {
    const db = new ClaimBaseService();
    await db.updateClaimBase(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): ClaimBase {
    const data = docSnap.data() as { [key: string]: any };
    return ClaimBase.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): ClaimBase {
    return new ClaimBase({
      id: id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      payerId: data["payerId"] ?? null,
      serviceDuration: data["serviceDuration"] ?? 0,
      serviceUnitCount: data["serviceUnitCount"] ?? 0,
      serviceCostPerUnit: data["serviceCostPerUnit"] ?? 0,
      billingCode: data["billingCode"] ?? "",
      billingModifier: data["billingModifier"] ?? "",
      description: data["description"] ?? "",
      enabled: data["enabled"] ?? false,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "payerId": this.payerId,
      "serviceDuration": Number(this.serviceDuration),
      "serviceUnitCount": Number(this.serviceUnitCount),
      "serviceCostPerUnit": Number(this.serviceCostPerUnit),
      "billingCode": this.billingCode,
      "billingModifier": this.billingModifier,
      "description": this.description,
      "enabled": this.enabled,
    };
  }
}
