import useCurrentUser from "@/hooks/useCurrentUser";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NeedsRecommendationTable from "../molecules/NeedsRecommendationTable";
import { ClientUser } from "@oben-core-web/models/client-user";
import { yupResolver } from "@hookform/resolvers/yup";
import recommendationLetterSchema from "@/resolvers/recommendationLetter";
import { TbCircleCheck } from "react-icons/tb";
import { CHWRecommendation } from "@oben-core-web/models/chw-recommendation";
import { UserName } from "@oben-core-web/models/user-name";
import { CHWRecommendationService } from "@oben-core-web/services/chw-recommendation-service";
import { FirebaseError } from "firebase/app";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import useRecommendationLetter from "@/hooks/useRecommendationLetter";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { WebUserService } from "@oben-core-web/services/web-user-service";

interface IPatientRecommendations {
  onRecommendationCreated: () => void;
}

interface IRecommendationForm {
  details: string;
  clientId: string;
  clientName: UserName;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  placeBasedCareProvId: string;
  clientPolicyNumber: string;
  payerId: string;
  createdDate: Date;
  latestDownloadDate: Date | null;
  attestation: string;
}

const PatientRecommendations = ({
  onRecommendationCreated
}: IPatientRecommendations) => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const toast = useToast();
  const { generate } = useRecommendationLetter();
  const { currentUser } = useCurrentUser();
  const [selectedPatient, setSelectedPatient] = useState<ClientUser>();
  const [recommendationLetter, setRecommendationLetter] =
    useState<CHWRecommendation>();
  const { register, handleSubmit, formState, reset, setError } =
    useForm<IRecommendationForm>({
      defaultValues: {
        details:
          "I declare that the following information is true and correct: 1. I am a Physician, Clinical Nurse Specialist (CNS), Pharmacist, Dentist, Licensed Clinical Social Worker (LCSW), Licensed Educational Psychologist (LEP), Licensed Marriage & Family Therapist (LMFT), Licensed Midwife, Licensed Professional Clinical Counselor (LPCC), Licensed Vocational Nurse (LVN), Nurse Midwife, Nurse Practitioner (NP), Pharmacist, Physician Assistant (PA), Podiatrist, Registered Nurse (RN), Psychologist, Public Health Nurse (PHN), or Registered Dental Hygienist (RDH). 2. I attest that the Medi-Cal Member listed below would benefit from Community Health Worker services.",
        clientId: "",
        clientName: UserName.fromMap({}),
        supervisingPhysId: "",
        supervisingPhysName: UserName.fromMap({}),
        placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? "",
        clientPolicyNumber: "",
        payerId: "",
        createdDate: new Date(),
        latestDownloadDate: null,
        attestation: ""
      },
      resolver: yupResolver(recommendationLetterSchema) as any
    });
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const fetchResetData = async () => {
      if (selectedPatient && placeBasedCareProvider) {
        if (placeBasedCareProvider.supervisingPhysId) {
          const webUserService = new WebUserService();
          const supervisingPhysician = await webUserService.getWebUser(
            placeBasedCareProvider.supervisingPhysId
          );
          if (!supervisingPhysician) {
            toast({
              status: "error",
              description: "No Supervising Physician found"
            });
          }
          reset({
            clientId: selectedPatient.uid,
            clientName: selectedPatient.name,
            supervisingPhysId: placeBasedCareProvider.supervisingPhysId,
            supervisingPhysName: supervisingPhysician.name,
            placeBasedCareProvId: placeBasedCareProvider.id ?? "",
            clientPolicyNumber: selectedPatient.payerPolicyNumber ?? "",
            payerId: selectedPatient.payerId ?? "",
            details:
              "I declare that the following information is true and correct: 1. I am a Physician, Clinical Nurse Specialist (CNS), Pharmacist, Dentist, Licensed Clinical Social Worker (LCSW), Licensed Educational Psychologist (LEP), Licensed Marriage & Family Therapist (LMFT), Licensed Midwife, Licensed Professional Clinical Counselor (LPCC), Licensed Vocational Nurse (LVN), Nurse Midwife, Nurse Practitioner (NP), Pharmacist, Physician Assistant (PA), Podiatrist, Registered Nurse (RN), Psychologist, Public Health Nurse (PHN), or Registered Dental Hygienist (RDH). 2. I attest that the Medi-Cal Member listed below would benefit from Community Health Worker services.",
            createdDate: new Date(),
            latestDownloadDate: null,
            attestation: ""
          });
        }
      }
    };
    fetchResetData();
  }, [selectedPatient, onToggle, reset, placeBasedCareProvider, toast]);

  const handlePatientSelected = (patient: ClientUser) => {
    setSelectedPatient(patient);
    onToggle();
  };

  const onSubmit = async (data: IRecommendationForm) => {
    if (!selectedPatient) return;
    if (
      data.attestation !==
      currentUser?.name.first + " " + currentUser?.name.last
    ) {
      setError("attestation", { message: "Invalid signature" });
      return;
    }
    const {
      clientId,
      clientName,
      supervisingPhysId,
      supervisingPhysName,
      placeBasedCareProvId,
      payerId,
      clientPolicyNumber,
      createdDate,
      latestDownloadDate,
      details
    } = data;
    const recommendation = new CHWRecommendation({
      modelId: "",
      clientId,
      clientName,
      supervisingPhysId,
      supervisingPhysName,
      placeBasedCareProvId,
      payerId,
      clientPolicyNumber,
      createdDate,
      latestDownloadDate,
      details
    });
    const chwRecommendationService = new CHWRecommendationService();
    await chwRecommendationService
      .addCHWRecommendation(recommendation)
      .then(async () => {
        const clientUserService = new ClientUserService();
        selectedPatient.hasRecommendationLetter = true;
        await clientUserService.updateClientUser(selectedPatient);
      })
      .then(() => {
        toast({
          status: "success",
          description: "Recommendation letter created"
        });
        onRecommendationCreated();
        setRecommendationLetter(recommendation);
        // generate(recommendation).download();
      })
      .catch((err) => {
        console.log("Failed to create recommendation letter", err);
        if (err instanceof FirebaseError) {
          if (err.code === "permission-denied") {
            toast({
              status: "error",
              description: "Insufficient permissions to take this action"
            });
          }
        } else {
          toast({
            status: "error",
            description: "Failed to create recommendation letter"
          });
        }
      });
  };
  formState.isValid;
  return (
    <Box flex={"1 1 0px"} h={"full"} w={"full"}>
      {isOpen && selectedPatient ? (
        <Modal
          isOpen={isOpen}
          onClose={onToggle}
          size={"full"}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent py={"5%"}>
            <ModalCloseButton />
            {!recommendationLetter ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader textAlign={"center"}>
                  Create Recommendation Letter
                </ModalHeader>
                <ModalBody display={"flex"} flexDirection={"column"} px={"15%"}>
                  <VStack
                    spacing={4}
                    alignItems={"flex-start"}
                    fontWeight={"bold"}
                  >
                    <Text>
                      I declare that the following information is true and
                      correct:
                    </Text>
                    <Text>
                      1. I am a Physician, Clinical Nurse Specialist (CNS),
                      Pharmacist, Dentist, Licensed Clinical Social Worker
                      (LCSW), Licensed Educational Psychologist (LEP), Licensed
                      Marriage & Family Therapist (LMFT), Licensed Midwife,
                      Licensed Professional Clinical Counselor (LPCC), Licensed
                      Vocational Nurse (LVN), Nurse Midwife, Nurse Practitioner
                      (NP), Pharmacist, Physician Assistant (PA), Podiatrist,
                      Registered Nurse (RN), Psychologist, Public Health Nurse
                      (PHN), or Registered Dental Hygienist (RDH).
                    </Text>
                    <Text>
                      2. I attest that the Medi-Cal Member listed below would
                      benefit from Community Health Worker services.
                    </Text>
                  </VStack>
                  <VStack w={"50%"} mt={8} alignSelf={"center"}>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"full"}
                    >
                      <Text as='b'>Patient Name</Text>
                      <Text>
                        {selectedPatient.name.first +
                          " " +
                          selectedPatient.name.last}
                      </Text>
                    </HStack>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"full"}
                    >
                      <Text as='b'>Member Insurance Number</Text>
                      <Text>{selectedPatient.payerPolicyNumber}</Text>
                    </HStack>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"full"}
                    >
                      <Text as='b'>Your Name</Text>
                      <Text>
                        {currentUser?.name.first + " " + currentUser?.name.last}
                      </Text>
                    </HStack>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"full"}
                    >
                      <Text as='b'>Provider Type</Text>
                      <Text>{currentUser?.userType}</Text>
                    </HStack>
                    <VStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"full"}
                    >
                      <FormControl isInvalid={!!formState.errors.attestation}>
                        <HStack
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          w={"full"}
                        >
                          <Text as='b'>Attestation</Text>
                          <Input
                            w={"50%"}
                            placeholder={
                              currentUser?.name.first +
                              " " +
                              currentUser?.name.last
                            }
                            {...register("attestation")}
                          ></Input>
                        </HStack>
                        <FormErrorMessage>
                          {formState.errors.attestation?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </VStack>
                  </VStack>
                </ModalBody>
                <ModalFooter
                  position='absolute'
                  w={"full"}
                  bottom={0}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Button
                    onClick={() => {
                      onToggle();
                      setSelectedPatient(undefined);
                    }}
                    px={"5rem"}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    isDisabled={!formState.isValid}
                    px={"5rem"}
                    colorScheme='teal'
                  >
                    Sign
                  </Button>
                </ModalFooter>
              </form>
            ) : (
              <VStack
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                alignSelf={"center"}
                py={"15%"}
                h={"full"}
                w={"30%"}
                spacing={5}
              >
                <VStack spacing={4}>
                  <TbCircleCheck size={32} color='teal' />
                  <Text textAlign={"center"}>
                    {`The community health worker recommendation letter for
                    ${recommendationLetter.clientName.fullName} has been saved. You can find it under the
                    patient profile.`}
                  </Text>
                </VStack>
                <Button
                  onClick={async () => {
                    await generate({
                      chwRecommendation: recommendationLetter,
                      shouldDownload: true
                    });
                  }}
                  w={"15rem"}
                >
                  Download
                </Button>
                <Button
                  onClick={() => {
                    onToggle();
                    setSelectedPatient(undefined);
                  }}
                  colorScheme='teal'
                  w={"15rem"}
                >
                  Done
                </Button>
              </VStack>
            )}
          </ModalContent>
        </Modal>
      ) : (
        <NeedsRecommendationTable
          onPatientSelected={handlePatientSelected}
          h={"full"}
          w={"full"}
        />
      )}
    </Box>
  );
};

export default PatientRecommendations;
