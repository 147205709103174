import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "@views/Home";
import Patients from "@views/Patients";
import Appointments from "@views/Appointments";
import PatientDetail from "@views/PatientDetail";
import UserProfile from "@views/UserProfile";
import CarePlan from "@views/CarePlan";
import NotFound from "@/components/views/NotFound";
import App from "../App"; // Import the App layout component

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />, // Use App as the layout
    children: [
      { path: "/", element: <Home /> },
      { path: "appointments", element: <Appointments /> },
      { path: "patients", element: <Patients /> },
      { path: "patient/:id", element: <PatientDetail /> },
      { path: "patient/:id/careplan/:carePlanId", element: <CarePlan /> },
      { path: "profile/:uid", element: <UserProfile /> },
      { path: "*", element: <NotFound /> }
    ]
  }
]);

export default appRouter;
