import { BillableItem } from "@oben-core-web/models/billable-item";
import { useState, useEffect, useCallback } from "react";
import { BillableItemService } from "@oben-core-web/services/billable-item-service";
import { DateTime } from "luxon";

const useBillableItems = (placeBasedCareProvId: string) => {
  const [billableItems, setBillableItems] = useState<BillableItem[]>([]);
  const fetchBillableItems = useCallback(async () => {
    const billableItemService = new BillableItemService();
    const items = await billableItemService.getAllBillableItems({
      placeBasedCareProvId,
      start: DateTime.now().minus({ year: 1 }).toJSDate(),
      end: DateTime.now().plus({ days: 30 }).toJSDate()
    });
    setBillableItems(items);
  }, [placeBasedCareProvId]);
  useEffect(() => {
    fetchBillableItems();
  }, [fetchBillableItems]);
  return { billableItems, refetch: fetchBillableItems };
};

export default useBillableItems;
