import { DocumentSnapshot } from "firebase/firestore";
import { CHWRecommendationService } from "../services/chw-recommendation-service";
import { UserName } from "./user-name";

export interface ICHWRecommendationData {
  modelId: string;
  clientId: string;
  clientName: UserName;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  placeBasedCareProvId: string;
  payerId: string;
  clientPolicyNumber: string;
  createdDate: Date;
  latestDownloadDate: Date | null;
  details: string;
}

export class CHWRecommendation {
  modelId: string;
  clientId: string;
  clientName: UserName;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  placeBasedCareProvId: string;
  payerId: string;
  clientPolicyNumber: string;
  createdDate: Date;
  latestDownloadDate: Date | null;
  details: string;

  constructor({
    modelId,
    clientId,
    clientName,
    supervisingPhysId,
    supervisingPhysName,
    placeBasedCareProvId,
    payerId,
    clientPolicyNumber,
    createdDate,
    latestDownloadDate,
    details
  }: ICHWRecommendationData) {
    this.modelId = modelId;
    this.clientId = clientId;
    this.clientName = clientName;
    this.supervisingPhysId = supervisingPhysId;
    this.supervisingPhysName = supervisingPhysName;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.payerId = payerId;
    this.clientPolicyNumber = clientPolicyNumber;
    this.createdDate = createdDate;
    this.latestDownloadDate = latestDownloadDate;
    this.details = details;
  }

  async updateDb(): Promise<void> {
    const db = new CHWRecommendationService();
    await db.updateCHWRecommendation(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): CHWRecommendation {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return CHWRecommendation.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): CHWRecommendation {
    return new CHWRecommendation({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      clientId: data["clientId"] ?? "",
      clientName: UserName.fromMap(data["clientName"] ?? {}),
      supervisingPhysId: data["supervisingPhysId"] ?? "",
      supervisingPhysName: UserName.fromMap(data["supervisingPhysName"] ?? {}),
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      payerId: data["payerId"] ?? "",
      clientPolicyNumber: data["clientPolicyNumber"] ?? "",
      createdDate: data["createdDate"] //
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1), // default to 1/1/0001
      latestDownloadDate: data["latestDownloadDate"]
        ? new Date(data["latestDownloadDate"])
        : null,
      details: data["details"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId,
      clientId: this.clientId,
      clientName: this.clientName.toJson(),
      supervisingPhysId: this.supervisingPhysId,
      supervisingPhysName: this.supervisingPhysName.toJson(),
      placeBasedCareProvId: this.placeBasedCareProvId,
      payerId: this.payerId,
      clientPolicyNumber: this.clientPolicyNumber,
      createdDate: this.createdDate.toISOString(),
      latestDownloadDate: this.latestDownloadDate?.toISOString() ?? null,
      details: this.details
    };
  }
}
