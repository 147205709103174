import {
  CollectionReference,
  collection,
  DocumentData,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
  QuerySnapshot,
  limit
} from "firebase/firestore";
import { Database } from "../../firebase";

import { Appointment } from "../models/appointment";
import { AppointmentStatus } from "../models/appointment-status-change";
import { GaLog } from "../utils/ga-log";

export class AppointmentService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "appointments";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of all appointments.
  // pharmacistIds (an array) and barberId (single value) are mutually exclusive.
  // start and end are optional and default to the current day.
  streamAppointments({
    pharmacistIds,
    barberId,
    start,
    end,
    statuses,
    handleSnapshot
  }: {
    pharmacistIds: string[] | null;
    barberId: string | null;
    start: Date | null;
    end: Date | null;
    statuses?: AppointmentStatus[];
    handleSnapshot: (
      snapshot: QuerySnapshot<DocumentData, DocumentData>
    ) => void;
  }): {
    // appointments: Ref<Appointment[]>;
    unsubscribe: (() => void) | undefined;
  } {
    // const appointmentsRef = ref<Appointment[]>([]);
    let unsubscribe: (() => void) | undefined;

    if (!pharmacistIds && !barberId) {
      throw new Error("At least one parameter must be provided");
    }

    const now = new Date();
    const startOfRange =
      start || new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfRange =
      end ||
      new Date(
        startOfRange.getFullYear(),
        startOfRange.getMonth(),
        startOfRange.getDate() + 1
      );

    const idKey = pharmacistIds ? "pharmacistId" : "barberId";
    const idValues = pharmacistIds ? pharmacistIds : [barberId];
    const queryFilters = [
      where(idKey, "in", idValues),
      where("date", ">=", startOfRange.toISOString()),
      where("date", "<", endOfRange.toISOString())
    ];
    if (statuses) {
      queryFilters.push(where("currentStatus", "in", statuses));
    }
    try {
      const queryRef = query(this._collectionReference, ...queryFilters);
      unsubscribe = onSnapshot(
        queryRef,
        (snapshot) => {
          handleSnapshot(snapshot);
          GaLog.readCollection(
            this._collectionReference.path,
            snapshot.docChanges().length,
            { isSubscription: true }
          );
        },
        (error) => {
          console.error("Error in onSnapshot:", error);
          // TODO: Do we need to throw an error here?
          GaLog.readCollection(this._collectionReference.path, 0, {
            isSubscription: true
          });
        }
      );
    } catch (error) {
      console.log("SOME ERROR HAPPENED");
      GaLog.readError(this._collectionReference.path, error, {
        isSubscription: true
      });
      throw error;
    }

    return { unsubscribe };
  }

  // Get the next appointment (within the next 12 months) for a specific client.
  async getNextClientAppointment(
    clientId: string
  ): Promise<Appointment | null> {
    const now = new Date();
    const startOfRange = now;
    const endOfRange = new Date(
      now.getFullYear() + 1,
      now.getMonth(),
      now.getDate()
    );
    const queryRef = query(
      this._collectionReference,
      where("clientId", "==", clientId),
      where("date", ">=", startOfRange.toISOString()),
      where("date", "<", endOfRange.toISOString()),
      orderBy("date"),
      limit(1)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      const appointments = qSnapshot.docs.map((docSnap: any) =>
        Appointment.fromFirestore(docSnap)
      );
      return appointments.length > 0 ? appointments[0] : null;
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // Returns appointments for either a specific pharmacist or a specific barber.
  // Default is to get all of their appointments for the current day (from midnight to midnight).
  async getStaffAppointments({
    pharmacistId,
    barberId,
    clientId,
    start,
    end
  }: {
    pharmacistId: string | string[] | null;
    barberId: string | null;
    clientId: string | null;
    start: Date | null;
    end: Date | null;
  }): Promise<Appointment[]> {
    if (!pharmacistId && !barberId) {
      throw new Error("At least one parameter must be provided");
    }

    const now = new Date();
    const startOfRange =
      start || new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfRange =
      end ||
      new Date(
        startOfRange.getFullYear(),
        startOfRange.getMonth(),
        startOfRange.getDate() + 1
      );
    const idKey = pharmacistId ? "pharmacistId" : "barberId";
    const idValues = pharmacistId ? [pharmacistId] : [barberId];
    const queryRef =
      clientId == null
        ? query(
            this._collectionReference,
            where(idKey, "in", idValues), // Filter by either pharmacistId or barberId
            where("date", ">=", startOfRange.toISOString()),
            where("date", "<", endOfRange.toISOString()),
            orderBy("date")
          )
        : query(
            this._collectionReference,
            where(idKey, "in", idValues), // Filter by either pharmacistId or barberId
            where("clientId", "==", clientId),
            where("date", ">=", startOfRange.toISOString()),
            where("date", "<", endOfRange.toISOString()),
            orderBy("date")
          );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        Appointment.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getAppointment(appointmentId: string): Promise<Appointment> {
    try {
      const docRef = doc(this._collectionReference, appointmentId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Appointment not found: ${appointmentId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return Appointment.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addAppointment(appointment: Appointment): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        appointment.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateAppointment(appointment: Appointment): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, appointment.id);
      await updateDoc(docRef, appointment.toJson());
      GaLog.updateDocument(this._collectionReference.path, appointment.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // Appointments can only be canceled, not deleted
  // async deleteAppointment(appointmentId: string): Promise<void> {}

  //* ===========================================================================
  //* Methods below are custom queries only present on web version of the service
  //* ===========================================================================

  // Get the all client appointments, with optional filtering by:
  // - clientId (defaults to all clientIds)
  // - pharmacistIds (defaults to all pharmacistIds)
  // - start (defaults to today at 00:00)
  // - end (defaults to 1 days from today at 00:00; i.e. today only)
  // - currentStatus (defaults to all statuses)
  async getAllClientAppointmentsFiltered({
    clientId,
    pharmacistIds,
    start,
    end,
    statuses
  }: {
    clientId?: string;
    pharmacistIds?: string[];
    start: Date | null;
    end: Date | null;
    statuses: AppointmentStatus[];
  }): Promise<Appointment[]> {
    const now = new Date();
    const startOfRange =
      start || new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfRange =
      end ||
      new Date(
        startOfRange.getFullYear(),
        startOfRange.getMonth(),
        startOfRange.getDate() + 1
      );
    const filters = [
      where("date", ">=", startOfRange.toISOString()),
      where("date", "<", endOfRange.toISOString()),
      orderBy("date")
    ];
    if (clientId) {
      filters.push(where("clientId", "==", clientId));
    }
    if (pharmacistIds) {
      filters.push(where("pharmacistId", "in", pharmacistIds));
    }
    if (statuses.length > 0) {
      filters.push(where("currentStatus", "in", statuses));
    }
    try {
      const queryRef = query(this._collectionReference, ...filters);
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        Appointment.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }
}
