import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { ClaimBase } from '../models/claim-base';
import { GaLog } from '../utils/ga-log';

export class ClaimBaseService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'claimBases';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get all billable item bases for a specified Place-based Care Provider.
  async getAllClaimBases({
    placeBasedCareProvId,
  }:{
    placeBasedCareProvId: string;
  }): Promise<ClaimBase[]> {
    const queryRef = query(
      this._collectionReference,
      where('placeBasedCareProvId', '==', placeBasedCareProvId)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => ClaimBase.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // get all claimBases for a specific billingCode (& PBCPId); should be unique, but handle multiple just in case
  async getClaimBasesByBillingCode({
    placeBasedCareProvId,
    billingCode,
  }:{
    placeBasedCareProvId: string;
    billingCode: string;
  }): Promise<ClaimBase[]> {
    const queryRef = query(
      this._collectionReference,
      where('placeBasedCareProvId', '==', placeBasedCareProvId),
      where('billingCode', '==', billingCode)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => ClaimBase.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getClaimBase(eventId: string): Promise<ClaimBase> {
    try {
      const docRef = doc(this._collectionReference, eventId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`ClaimBase not found: ${eventId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return ClaimBase.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addClaimBase(event: ClaimBase): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, event.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateClaimBase(event: ClaimBase): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, event.id);
      await updateDoc(docRef, event.toJson());
      GaLog.updateDocument(this._collectionReference.path, event.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // ClaimBases can only be updated, not deleted
  // async deleteClaimBase(eventId: string): Promise<void> {}
}
