import { Step } from "react-joyride";
import { useNavigate } from "react-router-dom";
import useCurrentUser from "./useCurrentUser";
import { UserType } from "@oben-core-web/constants/core-enums";

const useTourSteps = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const pharmacistHomeTour: Step[] = [
    {
      disableBeacon: true,
      target: ".pharmacist-dashboard-elements",
      content: "You can view some high level patient statistics here.",
      placement: "bottom"
    },
    {
      disableBeacon: true,
      target: ".upcoming-appointments-table",
      content:
        "This is a list of your upcoming appointments.  You can view these on a calendar view on the Appointments page",
      placement: "top"
    }
  ];

  const programManagerHomeTour: Step[] = [
    {
      disableBeacon: true,
      target: ".program-manager-overview",
      content:
        "This is the Program Manager Dashboard where you can quickly access patient and program management tools",
      placement: "center"
    },
    {
      disableBeacon: true,
      target: ".program-manager-badge",
      content: "Navigate around your dashboard using these buttons",
      placement: "bottom"
    },
    {
      disableBeacon: true,
      target: ".program-manager-enrollment-badge",
      content: "Enroll patients into the program here.",
      placement: "bottom"
    },
    {
      disableBeacon: true,
      target: ".program-manager-scheduling-badge",
      content: "See a list of patients who need appointments here.",
      placement: "bottom"
    },
    {
      disableBeacon: true,
      target: ".program-manager-appt-confirmation-badge",
      content: "Use this tool to confirm appointments.",
      placement: "bottom"
    },
    {
      disableBeacon: true,
      target: ".program-manager-payments-badge",
      content: "Manage pending payment requests here.",
      placement: "bottom"
    }
  ];

  const homeViewTour: Step[] = [
    {
      disableBeacon: true,
      target: ".center",
      content: "Welcome to the Oben Health web platform.",
      placement: "center"
    },
    {
      disableBeacon: true,
      target: ".nav-sidebar",
      content:
        "Use the navigation sidebar to go to different parts of the portal",
      placement: "right"
    },
    {
      disableBeacon: true,
      target: ".app-header",
      content:
        "Use the header toolbar to search for patients or access your user profile"
    }
  ];

  if (currentUser?.userType === UserType.ProgramManager) {
    homeViewTour.push(...programManagerHomeTour);
  } else {
    homeViewTour.push(...pharmacistHomeTour);
  }

  const userProfileViewTour: Step[] = [
    {
      disableBeacon: true,
      target: ".user-profile-view",
      content:
        "This is the user profile page.  It shows a users contact information, working hours, and any scheduling adjustments they may have made."
    },
    {
      disableBeacon: true,
      target: ".user-profile-contact-info",
      content: "You'll find your contact and role info here."
    },
    {
      disableBeacon: true,
      target: ".default-working-hours",
      content:
        "Your default working hours will be displayed here.  These are used to help your team schedule new patient appointments."
    },
    {
      disableBeacon: true,
      target: ".edit-user-profile-btn",
      content:
        "Click here to make changes to your user profile or set default working hours here."
    },
    {
      disableBeacon: true,
      target: ".schedule-adjustments",
      content:
        "If you ever need to add new working hours or need to make one-off changes to your working hours (for vacation time or something) you can set and view your custom schedule adjustments here."
    },
    {
      disableBeacon: true,
      target: ".schedule-adjustments-btn",
      content: "Click here to make schedule adjustments."
    }
  ];

  const patientsViewTour: Step[] = [
    {
      disableBeacon: true,
      target: ".patient-table",
      content:
        "This is the Patient List.  Use this tool to get a quick overview of who you will be working with.",
      data: {
        onPrev: {
          navigate: () => {
            navigate("/");
          }
        }
      }
    },
    {
      disableBeacon: true,
      target: ".ag-row",
      content:
        "By clicking on a row we can learn more about their blood pressure history.",
      data: {
        onNext: {
          action: () => {
            document.querySelector<HTMLElement>(".ag-row")?.click();
          }
        }
      }
    },
    {
      disableBeacon: true,
      target: ".bp-chart",
      content:
        "When you click on a row in the Patient Table, their blood pressure readings and goals appear in this chart."
    },
    {
      disableBeacon: true,
      target: ".nav-to-patient-detail",
      content:
        "You can click here or double click on any row to navigate to a Patient's detailed record",
      data: {
        onNext: {
          navigate: () => {
            const element: HTMLElement | null =
              document.querySelector<HTMLElement>(".ag-row");
            if (element) {
              const doubleClickEvent = new MouseEvent("dblclick", {
                bubbles: true, // The event will bubble up through the DOM
                cancelable: true, // The event can be canceled
                view: window // The window that the event is associated with
              });
              element.dispatchEvent(doubleClickEvent);
            }
          }
        }
      }
    }
  ];

  const patientDetailViewTour: Step[] = [
    {
      disableBeacon: true,
      target: "main",
      content:
        "This is the Patient Detail view where you can see all information related to this specific patient's care.",
      placement: "center"
    },
    {
      disableBeacon: true,
      target: ".patient-profile",
      content:
        "This section contains patient contact and demographic information, as well as some details about their membership in the program"
    },
    {
      disableBeacon: true,
      target: ".patient-profile-tabs",
      content:
        "This section can be used to manage a patient's care plan and view patient notes."
    }
  ];

  const appointmentsViewTour: Step[] = [
    {
      disableBeacon: true,
      target: "main",
      content:
        "This is the appointments view where you can view, manage, and schedule new patient appointments.",
      placement: "center",
      data: {
        onPrev: {
          navigate: () => {
            history.back();
          }
        }
      }
    },
    {
      disableBeacon: true,
      target: ".fc-view-harness",
      content: "All appointments will appear here in the calendar."
      // placement: "center"
    },
    {
      disableBeacon: true,
      target: ".fc-toolbar-chunk",
      content: "You can toggle between month and week views."
      // placement: "center"
    },
    {
      disableBeacon: true,
      target: ".fc-toolbar-chunk:nth-of-type(3)",
      content: "Use these controls to page through the calendar"
      // placement: "center"
    },
    {
      disableBeacon: true,
      target: ".appointments-pharmacist-select",
      content:
        "Selecting a pharmacist name from this dropdown will filter calendar appointments to just those assigned to the selected pharmacist.  It will also display their working hours for the currently displayed date range."
    },
    {
      disableBeacon: true,
      target: ".fc-view-harness",
      content:
        "To create an appointment select a pharmacist from the drop down menu, then click on any highlighted calendar cell to begin scheduling.",
      data: {
        onNext: {
          navigate: () => {
            navigate("/");
          }
        }
      }
    }
  ];

  const fullTourSteps: Step[] = [
    ...homeViewTour,
    {
      disableBeacon: true,
      target: "main",
      content: "Let's get started by checking out your user profile",
      placement: "center"
    },
    {
      disableBeacon: true,
      target: ".user-profile-menu",
      content:
        "You can navigate to your user profile or log out of the app by clicking here.",
      data: {
        onNext: {
          action: () => {
            document.querySelector<HTMLElement>(".user-profile-menu")?.click();
          }
        }
      }
    },
    {
      disableBeacon: true,
      target: ".my-profile-btn",
      content: "Let's check out your user profile.",
      data: {
        onNext: {
          action: () => {
            document.querySelector<HTMLElement>(".my-profile-btn")?.click();
          }
        }
      }
    },
    ...userProfileViewTour,
    {
      disableBeacon: true,
      target: ".patients-nav",
      content:
        "Next, we'll check out the Patients page where you can get a quick look at all of the patients in your program.",
      placement: "right",
      data: {
        onNext: {
          navigate: () => {
            navigate("/patients");
          }
        }
      }
    },
    ...patientsViewTour,
    ...patientDetailViewTour,
    {
      disableBeacon: true,
      target: ".appointments-nav",
      content:
        "Next, we'll check out the Appointments page where you can create, view, and manage patient appointments.",
      placement: "right",
      data: {
        onNext: {
          navigate: () => {
            navigate("/appointments");
          }
        }
      }
    },
    ...appointmentsViewTour,
    {
      disableBeacon: true,
      target: "main",
      content:
        "That's it! We hope this tour was helpful.  If you'd like to take the full tour again, use the 'Take the Tour' button in the navigation sidebar ",
      placement: "center"
    }
  ];

  const getTourSteps = ({
    stepSetName,
    withNavigation = true
  }: {
    stepSetName: string;
    withNavigation?: boolean;
  }) => {
    let stepSet: Step[];

    switch (stepSetName) {
      case "full":
        stepSet = fullTourSteps;
        break;
      case "home":
        stepSet = homeViewTour;
        break;
      case "patients":
        stepSet = patientsViewTour;
        break;
      case "patientDetail":
        stepSet = patientDetailViewTour;
        break;
      case "appointments":
        stepSet = appointmentsViewTour;
        break;
      case "userProfile":
        stepSet = userProfileViewTour;
        break;
      default:
        stepSet = [];
        break;
    }

    return stepSet.map((step) => {
      if (!withNavigation) {
        if (step.data?.onNext?.navigate) {
          delete step.data.onNext.navigate;
        }
        if (step.data?.onPrev?.navigate) {
          delete step.data.onPrev.navigate;
        }
      }
      return step;
    });
  };

  return { getTourSteps };
};

export default useTourSteps;
