import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { PlaceBasedCareProv } from '../models/place-based-care-prov';
import { GaLog } from '../utils/ga-log';

export class PlaceBasedCareProvService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'placeBasedCareProvs';
    this._collectionReference = collection(Database, this._collectionName);
  }

  async getAllPlaceBasedCareProvs(): Promise<PlaceBasedCareProv[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => PlaceBasedCareProv.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a PlaceBasedCareProv exists
  async validatePlaceBasedCareProv(placeBasedCareProvId: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, placeBasedCareProvId);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProv(placeBasedCareProvId: string): Promise<PlaceBasedCareProv> {
    try {
      const docRef = doc(this._collectionReference, placeBasedCareProvId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`PlaceBased Care Provider not found: ${placeBasedCareProvId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return PlaceBasedCareProv.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addPlaceBasedCareProv(placeBasedCareProv: PlaceBasedCareProv): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, placeBasedCareProv.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updatePlaceBasedCareProv(placeBasedCareProv: PlaceBasedCareProv): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, placeBasedCareProv.id);
      await updateDoc(docRef, placeBasedCareProv.toJson());
      GaLog.updateDocument(this._collectionReference.path, placeBasedCareProv.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // PlaceBasedCareProvs can only be updated, not deleted
  // async deletePlaceBasedCareProv(placeBasedCareProvId: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, placeBasedCareProvId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, placeBasedCareProvId);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
