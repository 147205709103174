import useClientCarePlans from "@/hooks/useClientCarePlans";
import useClientUserSubscription from "@/hooks/useClientUserSubscription";
import {
  VStack,
  Text,
  Box,
  HStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Checkbox
} from "@chakra-ui/react";
import { BpGoal } from "@oben-core-web/models/bp-goal";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { useForm } from "react-hook-form";
import BPChart from "./BPChart";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import isEqual from "lodash/isEqual";

interface ICPWizardStep1 {
  carePlanId: string;
  clientId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

interface ICPWizardStep1Form {
  bpGoal: {
    systolic: number;
    diastolic: number;
    setDate: Date;
  };
  isSmoker: boolean;
  hasDiabetes: boolean;
}

// TODO: add educational content if NOT IN edit mode
// TODO: add medication chart if IN edit mode

const CPWizardStep1 = ({
  carePlanId,
  clientId,
  onSubmit,
  onCancel
}: ICPWizardStep1) => {
  const { clientUser } = useClientUserSubscription(clientId);
  const { carePlans } = useClientCarePlans(clientId);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  const lastBpGoal = carePlan?.bpGoals.sort((a, b) =>
    a.setDate <= b.setDate ? 1 : -1
  )[0];
  const [projectedGoal, setProjectedGoal] = useState<BpGoal>();
  const carePlanRef = useRef(carePlan);
  const clientUserRef = useRef(clientUser);
  const { register, handleSubmit, reset, watch } = useForm<ICPWizardStep1Form>({
    defaultValues: {
      bpGoal: {
        systolic: lastBpGoal?.systolic ?? 0,
        diastolic: lastBpGoal?.diastolic ?? 0,
        setDate: new Date()
      },
      isSmoker: false,
      hasDiabetes: false
    }
  });
  const [systolic, diastolic] = watch(["bpGoal.systolic", "bpGoal.diastolic"]);
  useEffect(() => {
    if (systolic && diastolic) {
      setProjectedGoal(
        new BpGoal({ systolic, diastolic, setDate: new Date() })
      );
    }
  }, [systolic, diastolic]);
  useEffect(() => {
    if (
      !isEqual(carePlan, carePlanRef.current) ||
      !isEqual(clientUser, clientUserRef.current)
    ) {
      carePlanRef.current = carePlan;
      clientUserRef.current = clientUser;
      const lastBpGoal = carePlan?.bpGoals.sort((a, b) =>
        a.setDate <= b.setDate ? 1 : -1
      )[0];
      lastBpGoal &&
        reset({
          bpGoal: {
            ...lastBpGoal.toJson(),
            setDate: new Date()
          },
          isSmoker: clientUser?.isSmoker,
          hasDiabetes: clientUser?.isDiabetic
        });
    }
  }, [reset, carePlan, clientUser]);

  const submissionHandler = async (data: ICPWizardStep1Form) => {
    if (!carePlan) {
      return;
    }
    try {
      const { bpGoal: bpGoalData, isSmoker, hasDiabetes } = data;
      const bpGoal = new BpGoal(bpGoalData);

      await CarePlanTransactionService.saveBpGoalAndRiskFactors({
        clientId,
        carePlan,
        bpGoal,
        isSmoker,
        hasDiabetes
      }).then(() => {
        onSubmit && onSubmit();
      });
    } catch (e) {
      console.log({ error: e });
    }
  };
  return (
    <>
      {clientUser && carePlan && (
        <form onSubmit={handleSubmit(submissionHandler)}>
          <VStack
            spacing={4}
            key='step1-1'
            alignItems={"flex-start"}
            w={"100%"}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"space-evenly"}
              w={"full"}
            >
              <Box>
                <Text as={"h2"} fontSize={"large"}>
                  Your Current Blood Pressure
                </Text>
                {!!clientUser.currentBpReading && (
                  <Text as={"i"} fontSize={"small"}>
                    {"Recorded: " +
                      clientUser.currentBpReading?.readingDate.toDateString()}
                  </Text>
                )}
                <HStack>
                  <VStack>
                    <Text lineHeight={"1.5rem"}>
                      {clientUser.currentBpReading?.systolic ?? "-"}
                    </Text>
                    <Text as={"i"} fontSize={"small"} lineHeight={".75rem"}>
                      Systolic
                    </Text>
                  </VStack>
                  <VStack>
                    <Text as='b' lineHeight={"1.5rem"}>
                      /
                    </Text>
                    <Text fontSize={"small"} lineHeight={".75rem"}>
                      /
                    </Text>
                  </VStack>
                  <VStack>
                    <Text lineHeight={"1.5rem"}>
                      {clientUser.currentBpReading?.diastolic ?? ["-"]}
                    </Text>
                    <Text as={"i"} fontSize={"small"} lineHeight={".75rem"}>
                      Diastolic
                    </Text>
                  </VStack>
                </HStack>
              </Box>
              <Box />
              <Box>
                <Text as={"h2"} fontSize={"large"}>
                  Blood Pressure Goal
                </Text>
                <FormControl
                  display={"flex"}
                  dir={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormLabel fontSize={"sm"} w={"5rem"}>
                    Systolic
                  </FormLabel>
                  <Input
                    size={"md"}
                    type='number'
                    w={"5rem"}
                    {...register("bpGoal.systolic")}
                  />
                </FormControl>
                <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                  <FormLabel fontSize={"sm"} w={"5rem"}>
                    Diastolic
                  </FormLabel>
                  <Input
                    size={"md"}
                    type='number'
                    w={"5rem"}
                    {...register("bpGoal.diastolic")}
                  />
                </FormControl>
                {lastBpGoal && (
                  <Text fontSize={"small"} as={"i"} fontWeight={"normal"}>
                    Last set:{" "}
                    {DateTime.fromJSDate(lastBpGoal.setDate).toFormat(
                      "MM/dd/yyyy"
                    )}
                  </Text>
                )}
              </Box>
            </Flex>
            <Checkbox {...register("isSmoker")}>Current smoker</Checkbox>
            <Checkbox {...register("hasDiabetes")}>Has Diabetes</Checkbox>
            <Box w={"full"}>
              <BPChart
                patientId={clientId}
                height={250}
                width={"100%"}
                projectedGoal={projectedGoal}
              />
            </Box>
          </VStack>
          <HStack mt={"1rem"} justifyContent={"space-between"}>
            {onCancel && <Button onClick={onCancel}>Previous</Button>}
            {onSubmit && <Button type='submit'>Next</Button>}
          </HStack>
        </form>
      )}
    </>
  );
};

export default CPWizardStep1;
