import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import usePatients from "@/hooks/usePatients";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ColDef } from "ag-grid-community";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

interface IPatientTable {
  selectPatient: (patient: ClientUser) => void;
}

const PatientTable = ({ selectPatient }: IPatientTable) => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { clientUsers } = usePatients(placeBasedCareProvider?.id ?? "");
  const navigation = useNavigate();

  const patients = clientUsers.map((cu, i) => ({
    ...cu,
    id: cu.uid,
    name: cu.name ? cu.name?.first + " " + cu.name?.last : "Unnamed",
    ...(cu.dob
      ? { dob: DateTime.fromJSDate(cu.dob).toUTC().toFormat("DD") }
      : {}),
    ...(cu.registrationDate
      ? {
          duration: DateTime.fromJSDate(cu.registrationDate).toFormat("D")
        }
      : {}),
    ...(cu.registrationDate
      ? {
          registrationDate: DateTime.fromJSDate(
            new Date(cu.registrationDate)
          ).toFormat("D")
        }
      : {}),
    ...(cu.currentBpReading
      ? {
          lastBp:
            cu.currentBpReading.systolic + "/" + cu.currentBpReading.diastolic
        }
      : {}),
    ...(i === 0 ? { className: "nav-to-patient-detail" } : {})
  }));
  const navToPatient = (patientId: string) => {
    navigation("/patient/" + patientId);
  };

  const LinkToDetail = (params: any) => {
    return (
      <Link to={`/patient/${params.data.id}`}>
        <MdOutlineChevronRight
          className={params.data.className}
          size={24}
          style={{ alignSelf: "center", height: "100%" }}
        />
      </Link>
    );
  };

  const columnHeaders: ColDef[] = [
    { field: "name", headerName: "Patient", flex: 1 },
    {
      field: "dob",
      headerName: "Date of Birth",
      flex: 1
    },
    { field: "registrationDate", flex: 1 },
    // { field: "lastAppt" },
    { field: "lastBp", flex: 1 },
    // { field: "engagementStatus" },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: LinkToDetail,
      sortable: false,
      filter: false
    }
  ];

  const handleRowSelected = (row: any) => {
    selectPatient(row.data);
  };

  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        className='patient-table'
        rowData={patients}
        columnDefs={columnHeaders as any}
        rowSelection='single'
        onRowSelected={handleRowSelected}
        onRowDoubleClicked={(row) => row.data && navToPatient(row.data.id)}
        autoSizeStrategy={{ type: "fitProvidedWidth", width: 1215 }}
      />
    </div>
  );
};

export default PatientTable;
