import useClientUser from "@/hooks/useClientUser";
import useCurrentUser from "@/hooks/useCurrentUser";
import {
  HStack,
  VStack,
  Text,
  Center,
  Spinner,
  Card,
  SimpleGridProps,
  SimpleGrid,
  BoxProps
} from "@chakra-ui/react";
import { ClientType, ContactMethod } from "@oben-core-web/constants/core-enums";
import {
  Outreach,
  OutreachPurpose,
  OutreachStatus
} from "@oben-core-web/models/outreach";
import { UserName } from "@oben-core-web/models/user-name";
import { OutreachService } from "@oben-core-web/services/outreach-service";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import PatientOutreachForm from "./PatientOutreachForm";
import { AppointmentService } from "@oben-core-web/services/appointment-service";

interface IPatientOutreacher extends SimpleGridProps {
  clientId: string;
  patientOutreach?: Outreach;
  outreachPurpose?: OutreachPurpose;
  onOutreachLogged?: (outreachId?: string) => void;
  appointmentId?: string;
  formContainerProps?: BoxProps;
  historyContainerProps?: BoxProps;
}

const PatientOutreacher = ({
  patientOutreach,
  appointmentId,
  clientId,
  outreachPurpose,
  onOutreachLogged,
  formContainerProps,
  historyContainerProps,
  ...gridProps
}: IPatientOutreacher) => {
  const { currentUser } = useCurrentUser();
  const { clientUser } = useClientUser(clientId, true);
  const [outreach, setOutreach] = useState<Outreach | null>(
    patientOutreach ?? null
  );

  const createOutreach = useCallback(async () => {
    if (!clientUser || !currentUser) {
      return;
    }
    const outreachService = new OutreachService();
    const newOutreach = new Outreach({
      id: "",
      placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
      clientId: clientUser.uid,
      clientType: ClientType.ClientUser,
      clientEmail: clientUser.email ?? "",
      clientPhone: clientUser.phoneNumber ?? "",
      clientName: clientUser.name ?? UserName.fromMap({}),
      prefContactMethod: clientUser.prefContactMethod ?? ContactMethod.Phone,
      staffId: currentUser.uid ?? "",
      staffName: currentUser.name ?? UserName.fromMap({}),
      createdDate: new Date(),
      purpose: outreachPurpose ?? OutreachPurpose.Unknown,
      status: OutreachStatus.New,
      attempts: []
    });
    await outreachService.addOutreach(newOutreach).then(async (outreachId) => {
      newOutreach.id = outreachId;
      setOutreach(newOutreach);
      if (appointmentId) {
        const appointmentService = new AppointmentService();
        const appointment = await appointmentService.getAppointment(
          appointmentId
        );
        appointment.outreachId = outreachId;
        await appointmentService.updateAppointment(appointment);
      }
    });
  }, [currentUser, clientUser, outreachPurpose, appointmentId]);

  const fetchOutreach = useCallback(async (outreachId: string) => {
    const outreachService = new OutreachService();
    await outreachService
      .getOutreach(outreachId)
      .then((outreach) => setOutreach(outreach));
  }, []);

  useEffect(() => {
    if (patientOutreach && !outreach) {
      fetchOutreach(patientOutreach.id);
    } else if (!outreach) {
      createOutreach();
    }
  }, [createOutreach, fetchOutreach, patientOutreach, outreach]);

  if (!outreach) {
    return (
      <Center h={"full"} w={"full"}>
        <Spinner />
      </Center>
    );
  }

  return (
    <SimpleGrid {...gridProps}>
      {/* <VStack>
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <Text>Patient</Text>
          <Text fontWeight={"bold"}>{outreach.clientName?.display}</Text>
        </HStack>
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <Text>Preferred contact method</Text>
          <Text fontWeight={"bold"}>{outreach.prefContactMethod}</Text>
        </HStack>
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <Text>Phone</Text>
          <Text fontWeight={"bold"}>{outreach.clientPhone}</Text>
        </HStack>
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <Text>Email</Text>
          <Text fontWeight={"bold"}>{outreach.clientEmail}</Text>
        </HStack>
      </VStack> */}
      <VStack w={"full"} {...formContainerProps}>
        <PatientOutreachForm
          fetchOutreach={fetchOutreach}
          onAttemptSaved={() => {
            fetchOutreach(outreach.id);
            onOutreachLogged && onOutreachLogged(outreach.id);
          }}
          outreachItem={outreach}
          appointmentId={appointmentId}
        />
      </VStack>
      <VStack
        w={"full"}
        alignItems={"flex-start"}
        spacing={2}
        {...historyContainerProps}
        // mt={4}
      >
        <Text fontWeight={"bold"}>Attempt History</Text>
        {outreach.attempts
          .sort((a, b) => (a.date > b.date ? -1 : 1))
          .map((a, i) => (
            <Card
              key={`outreach-${outreach.id}-attempt-${i}`}
              alignItems={"flex-start"}
              variant='outline'
              p={2}
              w={"full"}
            >
              <HStack
                w={"full"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text as={"i"}>{a.staffName.display}</Text>
                <Text as={"i"}>
                  {DateTime.fromJSDate(a.date).toFormat("ff")}
                </Text>
              </HStack>
              <Text as={"b"}>{startCase(a.result)}</Text>
              <Text>{a.note}</Text>
            </Card>
          ))}
      </VStack>
    </SimpleGrid>
  );
};

export default PatientOutreacher;
