import { ClientUser } from "@oben-core-web/models/client-user";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import { useCallback, useEffect, useState } from "react";

const useClientUser = (
  clientId: string = "",
  fetchInitially: boolean = false
) => {
  const [clientUser, setClientUser] = useState<ClientUser>();
  const fetchClientUser = useCallback(async (clientId: string) => {
    const clientUserService = new ClientUserService();
    const client = await clientUserService.getClientUser(clientId);
    setClientUser(client);
    return client;
  }, []);
  useEffect(() => {
    if (fetchInitially) {
      fetchClientUser(clientId);
    }
  }, [clientId, fetchInitially, fetchClientUser]);
  return { clientUser, fetchClientUser };
};

export default useClientUser;
