import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where
} from "firebase/firestore";
import { CloudFunctions, Database } from "../../firebase";
import { Appointment } from "../models/appointment";
import { WebUser } from "../models/web-user";
import {
  BarberUser
  // StripeStatus
} from "../models/barber-user";
import { BillableEvent, BillableEventType } from "../models/billable-event";
import { AppointmentStatus } from "../models/appointment-status-change";
import { DateTime } from "luxon";
import {
  BillableItem,
  BillableItemType,
  billableItemTypeData
} from "../models/billable-item";
import { BillStatus, BillStatusChange } from "../models/bill-status-change";
import { httpsCallable } from "firebase/functions";
import { CloudFunctionResponse } from "../models/cloud-function-response";
import { ClientType } from "../constants/core-enums";
import { ClaimBase } from "../models/claim-base";
import { startCase } from "lodash";

// TODO: update billable items to only approve based on appointment status -- not create actual billable items
export class BillingTransactionService {
  // static async createBillableFromApptStatus({
  //   appointmentId,
  //   placeBasedCareProvId,
  //   editorId,
  //   statusChange,
  //   approveImmediately = false
  // }: {
  //   appointmentId: string;
  //   placeBasedCareProvId: string;
  //   editorId: string;
  //   statusChange: AppointmentStatus;
  //   approveImmediately: boolean;
  // }) {
  //   if (!appointmentId || !statusChange || !editorId || !placeBasedCareProvId) {
  //     throw new Error("Missing required parameters");
  //   }
  //   try {
  //     await runTransaction(Database, async (transaction) => {
  //       // lookup required documents
  //       const editorRef = doc(Database, `webUsers/${editorId}`);
  //       const editorDoc = WebUser.fromFirestore(await getDoc(editorRef));
  //       const appointmentRef = doc(Database, `appointments/${appointmentId}`);
  //       const appointmentDoc = Appointment.fromFirestore(
  //         await getDoc(appointmentRef)
  //       );
  //       if (!appointmentDoc) {
  //         throw new Error("Could not fetch appointment");
  //       }
  //       if (!editorDoc) {
  //         throw new Error("Could not fetch editing web user");
  //       } else if (!editorDoc.placeBasedCareProvId) {
  //         throw new Error("Missing place based care provider id");
  //       }
  //       const barberDocRef = doc(
  //         Database,
  //         `barbers/${appointmentDoc.barberId}`
  //       );
  //       const barberDoc = BarberUser.fromFirestore(await getDoc(barberDocRef));
  //       if (!barberDoc) {
  //         throw new Error("Could not fetch barber");
  //       }

  //       // validate that this is the only billable event for this appointment
  //       const billableEventsCollectionRef = collection(
  //         Database,
  //         "billableEvents"
  //       );
  //       const existingBillableEventsQuery = query(
  //         billableEventsCollectionRef,
  //         where("appointmentId", "==", appointmentDoc.id),
  //         where("clientId", "==", appointmentDoc.clientId)
  //       );
  //       const billableEventsQueryResult = await getDocs(
  //         existingBillableEventsQuery
  //       );
  //       const hasExistingBillableEvents = billableEventsQueryResult.size > 0;
  //       if (hasExistingBillableEvents)
  //         throw new Error("This appointment already has a billable event");

  //       // set fee amount
  //       const { serviceFee, noShowFee, cancelFee, cancelWindow } = barberDoc;
  //       let billableItemFee: number = 0;
  //       let billableItemDescription: string;
  //       let submitCharge = false;
  //       switch (statusChange) {
  //         case AppointmentStatus.Canceled:
  //           if (appointmentDoc.date && cancelWindow && cancelFee) {
  //             const now = DateTime.fromJSDate(new Date());
  //             const cancelWindowStartDate = DateTime.fromJSDate(
  //               appointmentDoc.date
  //             ).minus({ days: cancelWindow });
  //             const appointmentStart = DateTime.fromJSDate(appointmentDoc.date);
  //             const appointmentEnd = DateTime.fromJSDate(
  //               appointmentDoc.date
  //             ).plus({ minutes: appointmentDoc.length });
  //             // must cancel before start of appointment for cancellation fee
  //             if (now >= cancelWindowStartDate && now < appointmentStart) {
  //               // appointment cancelled within cancellation window
  //               billableItemFee = cancelFee ?? 0;
  //               billableItemDescription =
  //                 "Appointment cancelled within cancellation window";
  //             } else if (now > appointmentStart && now < appointmentEnd) {
  //               // cancelled during appointment time, no-show fee
  //               billableItemFee = noShowFee ?? 0;
  //               billableItemDescription =
  //                 "Appointment cancelled during appointment window";
  //             } else {
  //               // if cancelling before window, pay no fee
  //               billableItemFee = 0;
  //               billableItemDescription =
  //                 "Appointment cancelled before cancellation window";
  //             }
  //           } else {
  //             throw new Error("Cancellation missing required params");
  //           }
  //           break;
  //         case AppointmentStatus.Completed:
  //           billableItemFee = serviceFee ?? 0;
  //           billableItemDescription = "Appointment started";
  //           submitCharge = true;
  //           break;
  //         case AppointmentStatus.NoShow:
  //           billableItemFee = noShowFee ?? 0;
  //           billableItemDescription = "Appointment no-show";
  //           break;
  //         case AppointmentStatus.New:
  //           billableItemFee = serviceFee ?? 0;
  //           billableItemDescription = "Appointment started";
  //           submitCharge = true;
  //           console.log("Unconfirmed appointment started");
  //           break;
  //         default:
  //           billableItemFee = 0;
  //           billableItemDescription = "";
  //           break;
  //       }
  //       // only create billableEvent/Item if there is a fee to attach -- TODO: confirm that this is correct
  //       if (billableItemFee === 0) {
  //         throw new Error("Cannot create billable item with value 0");
  //       }
  //       // create billableEvent
  //       const billableEventDocRef = doc(billableEventsCollectionRef);
  //       const billableEventData = new BillableEvent({
  //         id: billableEventDocRef.id,
  //         appointmentId,
  //         clientId: appointmentDoc.clientId,
  //         clientType: ClientType.ClientUser,
  //         date: new Date(),
  //         eventType: BillableEventType.RxConsult,
  //         placeBasedCareProvId: editorDoc.placeBasedCareProvId!
  //       });
  //       await transaction.set(billableEventDocRef, billableEventData.toJson());

  //       // create billableItem
  //       const billableItemCollectionRef = collection(
  //         Database,
  //         "billableEvents",
  //         billableEventDocRef.id,
  //         "billableItems"
  //       );
  //       const billableItemDoc = doc(billableItemCollectionRef);
  //       const statusChanges = [
  //         new BillStatusChange({
  //           status: BillStatus.New,
  //           date: new Date(),
  //           editorType: editorDoc.userType,
  //           editorId: editorDoc.uid,
  //           details: billableItemDescription
  //         })
  //       ];
  //       let lastStatus: BillStatus = BillStatus.New;
  //       if (approveImmediately) {
  //         statusChanges.push(
  //           new BillStatusChange({
  //             status: BillStatus.ApprovedForSubmission,
  //             date: new Date(),
  //             editorType: editorDoc!.userType,
  //             editorId: editorDoc!.uid,
  //             details: `Approved by ${editorDoc!.userType} - ${
  //               editorDoc!.name.display
  //             }`
  //           })
  //         );
  //         lastStatus = BillStatus.ApprovedForSubmission;
  //       }
  //       const billableItem = new BillableItem({
  //         modelId: billableItemDoc.id,
  //         billableEventId: billableEventDocRef.id,
  //         clientId: appointmentDoc.clientId,
  //         clientName: appointmentDoc.clientName,
  //         serviceDate: appointmentDoc.date!,
  //         placeBasedCareProvId,
  //         billableItemType: BillableItemType.PrimaryService,
  //         description: `${
  //           billableItemTypeData[BillableItemType.PrimaryService]
  //             .englishDescription
  //         } ${appointmentDoc.clientName.display} - ${billableItemDescription}`,
  //         amount: billableItemFee,
  //         barberId: appointmentDoc.barberId,
  //         barberName: barberDoc.name,
  //         billStatusChanges: statusChanges,
  //         currentStatus: lastStatus,
  //         stripePmtStatusChanges: []
  //       });
  //       await transaction.set(billableItemDoc, billableItem.toJson());

  //       // update appointment
  //       await transaction.update(appointmentRef, {
  //         billableEventId: billableEventDocRef.id
  //       });
  //       return {
  //         submitCharge,
  //         editorDoc,
  //         barberDoc,
  //         billableItemFee,
  //         billableEventId: billableEventDocRef.id
  //       };
  //     }).then(
  //       async ({ submitCharge, editorDoc, barberDoc, billableEventId }) => {
  //         if (
  //           submitCharge &&
  //           barberDoc.stripeStatus === StripeStatus.TransfersEnabled
  //         ) {
  //           // call stripe api
  //           const createCustomerCharge = httpsCallable<
  //             {
  //               sender: string;
  //               receiver: string;
  //               billableEventId: string;
  //               approverId: string;
  //             },
  //             CloudFunctionResponse
  //           >(CloudFunctions, "createCustomerCharge");
  //           const result = await createCustomerCharge({
  //             sender: editorDoc.placeBasedCareProvId!,
  //             receiver: barberDoc.uid,
  //             billableEventId,
  //             approverId: editorDoc!.uid
  //           });
  //           if (result.data.error) {
  //             throw result.data.error;
  //           } else {
  //             console.log(result.data.data);
  //           }
  //         }
  //       }
  //     );
  //   } catch (e) {
  //     console.log("Error creating billable from appointment status change", e);
  //     throw e;
  //   }
  // }

  static async getAppointmentFeeData({
    appointmentId,
    feeType
  }: {
    appointmentId: string;
    feeType: AppointmentStatus;
  }) {
    const appointmentRef = doc(Database, `appointments/${appointmentId}`);
    const appointmentDoc = await getDoc(appointmentRef);
    if (!appointmentDoc.exists()) {
      throw new Error("Appointment not found");
    }
    const appointment = Appointment.fromFirestore(appointmentDoc);

    const barberRef = doc(Database, `barbers/${appointment.barberId}`);
    const barberDoc = await getDoc(barberRef);
    if (!barberDoc.exists()) {
      throw new Error("Barber not found");
    }
    const barber = BarberUser.fromFirestore(barberDoc);
    const { serviceFee, noShowFee, cancelFee, cancelWindow } = barber;

    let fee = 0;
    let billableItemDescription: string = "";
    switch (feeType) {
      case AppointmentStatus.LateCanceled:
        if (appointment.date && cancelWindow && cancelFee) {
          const now = DateTime.now();
          const cancelWindowStartDate = DateTime.fromJSDate(
            appointment.date
          ).minus({ days: cancelWindow });

          if (now > cancelWindowStartDate) {
            fee = cancelFee ?? 0;
            billableItemDescription =
              "Appointment cancelled after cancellation window";
          }
          // if fee hasn't been set by this point, just use the default 0
        }
        break;
      case AppointmentStatus.NoShow:
        fee = noShowFee ?? 0;
        billableItemDescription = "Appointment no-show";
        break;
      case AppointmentStatus.Completed:
        fee = serviceFee ?? 0;
        billableItemDescription = "Appointment completed";
        break;
      default:
        fee = 0;
        billableItemDescription = "";
        break;
    }
    return { fee, description: billableItemDescription };
  }

  static async getCHWReimbursementBase({
    placeBasedCareProvId,
    billingCode
  }: {
    placeBasedCareProvId: string;
    billingCode: string;
  }) {
    const placeBasedCareProvRef = doc(
      Database,
      `placeBasedCareProvs/${placeBasedCareProvId}`
    );
    const placeBasedCareProvDoc = await getDoc(placeBasedCareProvRef);
    if (!placeBasedCareProvDoc.exists()) {
      throw new Error("Place based care provider not found");
    }
    const claimBaseCollectionRef = collection(Database, "claimBases");
    const claimBaseQuery = query(
      claimBaseCollectionRef,
      where("placeBasedCareProvId", "==", placeBasedCareProvId),
      where("billingCode", "==", billingCode),
      where("enabled", "==", true)
    );
    const claimBaseDocs = await getDocs(claimBaseQuery);
    if (claimBaseDocs.empty) {
      throw new Error(
        "No claim bases found for this place based care provider"
      );
    }
    const claimBase = claimBaseDocs.docs[0]; // only one matching claim base should exist
    if (!claimBase) {
      throw new Error("No CHW reimbursement base found");
    }
    return ClaimBase.fromFirestore(claimBase);
  }

  static async submitToProgramManager({
    appointmentId,
    placeBasedCareProvId,
    editorId,
    statusChange
  }: {
    appointmentId: string;
    placeBasedCareProvId: string;
    editorId: string;
    statusChange: AppointmentStatus;
  }) {
    if (!appointmentId || !statusChange || !editorId || !placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        // lookup required documents
        const editorRef = doc(Database, `webUsers/${editorId}`);
        const editorDoc = WebUser.fromFirestore(await getDoc(editorRef));
        const appointmentRef = doc(Database, `appointments/${appointmentId}`);
        const appointmentDoc = Appointment.fromFirestore(
          await getDoc(appointmentRef)
        );
        if (!appointmentDoc) {
          throw new Error("Could not fetch appointment");
        }
        const barberRef = doc(Database, `barbers/${appointmentDoc.barberId}`);
        const barberDoc = BarberUser.fromFirestore(await getDoc(barberRef));
        if (!editorDoc) {
          throw new Error("Could not fetch editing web user");
        } else if (!editorDoc.placeBasedCareProvId) {
          throw new Error("Missing place based care provider id");
        }
        if (!appointmentDoc.billableEventId) {
          // create billable structures
          const billableEventCollectionRef = collection(
            Database,
            "billableEvents"
          );
          const billableEventDocRef = doc(billableEventCollectionRef);
          const billableEvent = new BillableEvent({
            id: billableEventDocRef.id,
            appointmentId,
            clientId: appointmentDoc.clientId,
            clientType: ClientType.ClientUser,
            date: new Date(),
            eventType: BillableEventType.RxConsult,
            placeBasedCareProvId: editorDoc.placeBasedCareProvId!
          });
          transaction.set(billableEventDocRef, billableEvent.toJson());
          const billableItemCollectionRef = collection(
            Database,
            `billableEvents/${billableEventDocRef.id}/billableItems`
          );
          transaction.update(appointmentRef, {
            billableEventId: billableEventDocRef.id
          });
          const serviceItemDocRef = doc(billableItemCollectionRef);
          const serviceFeeData = await this.getAppointmentFeeData({
            appointmentId,
            feeType: statusChange
          });
          if (serviceFeeData.fee !== 0) {
            const serviceItem = new BillableItem({
              modelId: serviceItemDocRef.id,
              billableEventId: billableEventDocRef.id,
              clientId: appointmentDoc.clientId,
              clientName: appointmentDoc.clientName,
              serviceDate: appointmentDoc.date!,
              placeBasedCareProvId,
              billableItemType: BillableItemType.PrimaryService,
              description: `${
                billableItemTypeData[BillableItemType.PrimaryService]
                  .englishDescription
              } ${appointmentDoc.clientName.display} - ${
                serviceFeeData.description
              }`,
              amount: serviceFeeData.fee,
              barberId: appointmentDoc.barberId,
              barberName: barberDoc.name,
              billStatusChanges: [
                new BillStatusChange({
                  status: BillStatus.New,
                  date: new Date(),
                  editorType: editorDoc.userType,
                  editorId: editorDoc.uid,
                  details: `Appointment ${startCase(statusChange)}`
                }),
                new BillStatusChange({
                  status: BillStatus.ApprovedForSubmission,
                  date: new Date(),
                  editorType: editorDoc.userType,
                  editorId: editorDoc.uid,
                  details: `Approved by ${editorDoc.name.fullName}`
                })
              ],
              currentStatus: BillStatus.ApprovedForSubmission,
              stripePmtStatusChanges: []
            });
            transaction.set(serviceItemDocRef, serviceItem.toJson());
          }

          if (statusChange === AppointmentStatus.Completed) {
            // only create chw reimbursement if appointment is completed
            const reimbusementItemDocRef = doc(billableItemCollectionRef);
            const CHWReimbursementBase = await this.getCHWReimbursementBase({
              placeBasedCareProvId,
              billingCode: "98960" //! Hardcoded for now
            });
            if (CHWReimbursementBase.amount === 0) {
              console.log("CHW reimbursement base amount is 0, skipping");
              throw new Error("No CHW reimbursement set for this provider");
            }
            const clientRef = doc(
              Database,
              `clients/${appointmentDoc.clientId}`
            );
            const clientDoc = await getDoc(clientRef);
            if (!clientDoc.exists()) {
              throw new Error("Client not found for chw reimbursement");
            }
            const reimbursementItem = new BillableItem({
              modelId: reimbusementItemDocRef.id,
              billableEventId: billableEventDocRef.id,
              clientId: appointmentDoc.clientId,
              clientName: appointmentDoc.clientName,
              serviceDate: appointmentDoc.date!,
              placeBasedCareProvId,
              billableItemType: BillableItemType.CHWService,
              description: `${
                billableItemTypeData[BillableItemType.CHWService]
                  .englishDescription
              } ${appointmentDoc.barberName.display} - Appointment with ${
                appointmentDoc.clientName.display
              }`,
              amount: CHWReimbursementBase.amount,
              barberId: appointmentDoc.barberId,
              barberName: barberDoc.name,
              billStatusChanges: [
                new BillStatusChange({
                  status: BillStatus.New,
                  date: new Date(),
                  editorType: editorDoc.userType,
                  editorId: editorDoc.uid,
                  details: "CHW Services provided"
                }),
                new BillStatusChange({
                  status: BillStatus.ApprovedForSubmission,
                  date: new Date(),
                  editorType: editorDoc.userType,
                  editorId: editorDoc.uid,
                  details: "CHW reimbursement approved for submission"
                })
              ],
              currentStatus: BillStatus.ApprovedForSubmission,
              stripePmtStatusChanges: []
            });
            transaction.set(reimbusementItemDocRef, reimbursementItem.toJson());
          }
        } else {
          const billableEventRef = doc(
            Database,
            "billableEvents",
            appointmentDoc.billableEventId
          );
          const billableEventDoc = await getDoc(billableEventRef);
          const billableEvent = BillableEvent.fromFirestore(billableEventDoc);
          const billableItemCollectionRef = collectionGroup(
            Database,
            "billableItems"
          );
          const billableItemsQuery = await getDocs(
            query(
              billableItemCollectionRef,
              where("billableEventId", "==", billableEvent.id)
            )
          );
          if (billableItemsQuery.empty) {
            throw new Error("No billable items found for this billable event");
          }
          // billable items exist, filter down to those which havent been approved, submitted, or paid
          const billableItems = billableItemsQuery.docs
            .map((doc) => BillableItem.fromFirestore(doc))
            .filter((bi) => {
              const billStatusChanges = bi.billStatusChanges.map(
                (bsc) => bsc.status
              );
              return (
                !billStatusChanges.includes(BillStatus.ApprovedForSubmission) &&
                !billStatusChanges.includes(BillStatus.Submitted) &&
                !billStatusChanges.includes(BillStatus.Paid)
              );
            });

          if (billableItems.length === 0) {
            throw new Error("No billable items require approval");
          }
          // update billableItems
          await Promise.all(
            billableItems.map(async (billableItem) => {
              const billableItemStatusChange = new BillStatusChange({
                status: BillStatus.ApprovedForSubmission,
                date: new Date(),
                editorId: editorDoc.uid,
                editorType: editorDoc.userType,
                details: "Item approved for submission"
              });
              transaction.update(
                doc(
                  Database,
                  `billableEvents/${billableItem.billableEventId}/billableItems/${billableItem.modelId}`
                ),
                {
                  billStatusChanges: [
                    ...billableItem.billStatusChanges,
                    billableItemStatusChange
                  ].map((bsc) => bsc.toJson()),
                  currentStatus: billableItemStatusChange.status
                }
              );
            })
          );
        }
      });
    } catch (e) {
      console.log("Error calling submitToProgramManager", e);
      throw e;
    }
  }

  static async approveBillableItem({
    billableEventId,
    editorId,
    receiverId,
    billStatusUpdate
  }: {
    billableEventId: string;
    editorId: string;
    receiverId: string;
    billStatusUpdate: BillStatus;
  }) {
    if (!billStatusUpdate) throw new Error("No billStatusUpdate provided");
    if (!receiverId) throw new Error("No recipient specified");

    const editorRef = doc(Database, `webUsers/${editorId}`);
    const editorDoc = await getDoc(editorRef);
    if (!editorDoc.exists) {
      throw new Error("Unable to approve payment: approver missing");
    }
    const editor = WebUser.fromFirestore(editorDoc);

    try {
      const createCustomerCharge = httpsCallable<
        {
          sender: string;
          receiver: string;
          billableEventId: string;
          approverId: string;
        },
        CloudFunctionResponse
      >(CloudFunctions, "createCustomerCharge");
      const result = await createCustomerCharge({
        sender: editor.placeBasedCareProvId!,
        receiver: receiverId,
        billableEventId,
        approverId: editor!.uid
      });
      if (result.data.error) {
        throw result.data.error;
      } else {
        console.log(result.data.data);
      }
    } catch (e) {
      console.log("Failed approve billable item", e);
      throw e;
    }
  }
}
