import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { BillableEvent } from '../models/billable-event';
import { GaLog } from '../utils/ga-log';

export class BillableEventService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'billableEvents';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get all billable events within a specified date range. default is current month (to date).
  async getAllBillableEvents({
    placeBasedCareProvId,
    start,
    end,
  }: {
    placeBasedCareProvId: string;
    start: Date | null;
    end: Date | null;
  }): Promise<BillableEvent[]> {
    const now = new Date();
    const startOfRange =
      start || new Date(
        now.getFullYear(),
        now.getMonth(),
        1,
      );
    const endOfRange =
      end || now; 
    const queryRef = query(
      this._collectionReference,
      where('placeBasedCareProvId', '==', placeBasedCareProvId),
      where('date', '>=', startOfRange.toISOString()),
      where('date', '<', endOfRange.toISOString())
    );

    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => BillableEvent.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getClientBillableEvents(clientId: string): Promise<BillableEvent[]> {
    const queryRef = query(
      this._collectionReference,
      where('clientId', '==', clientId),
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => BillableEvent.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  createBillableEventId(): string {
    try {
      const docRef = doc(this._collectionReference);
      GaLog.addDocument(this._collectionReference.path, docRef.id); // log the new document ID (which is really just a placeholder)
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getBillableEvent(eventId: string): Promise<BillableEvent> {
    try {
      const docRef = doc(this._collectionReference, eventId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`BillableEvent not found: ${eventId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return BillableEvent.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addBillableEvent(event: BillableEvent): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, event.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateBillableEvent(event: BillableEvent): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, event.id);
      await updateDoc(docRef, event.toJson());
      GaLog.updateDocument(this._collectionReference.path, event.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // not used by web portal
  // async updateBillableEventTx(event: BillableEvent, transaction: Transaction): Promise<void> {
  // }

  // // BillableEvents can only be canceled, not deleted
  // async deleteBillableEvent(eventId: string): Promise<void> {}
}
