import {
  Box,
  FormLabel,
  HStack,
  Select,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import React, { useState } from "react";
import CHWClaimTable from "../molecules/CHWClaimTable";
import { Claim } from "@oben-core-web/models/claim";
import CHWClaimReview from "../molecules/CHWClaimReview";
import useClaims from "@/hooks/useClaims";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { ClaimStatus } from "@oben-core-web/models/claim-status-change";

interface ICHWInvoices {
  onInvoiceProcessed: () => void;
}

const CHWInvoices = ({ onInvoiceProcessed }: ICHWInvoices) => {
  const { isOpen, onToggle } = useDisclosure();
  const [claim, setClaim] = useState<Claim>();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { claims, fetchClaims } = useClaims({
    placeBasedCareProvId: placeBasedCareProvider?.id ?? "",
    fetchInitially: true
  });
  const [activeFilter, setActiveFilter] =
    useState<keyof typeof claimFilters>("requiresResponse");

  return (
    <Box flex={"1 1 0px"} h={"full"} w={"full"}>
      {isOpen && claim ? (
        <CHWClaimReview
          isOpen={isOpen}
          toggleModal={onToggle}
          claim={claim}
          onInvoiceProcessed={onInvoiceProcessed}
          refetchClaims={fetchClaims}
        />
      ) : (
        <VStack h={"full"}>
          <HStack
            w={"full"}
            alignItems={"center"}
            // alignItems={"flex-start"}
            justifyContent={"center"}
            spacing={2}
            border={"1px solid lightgrey"}
            p={2}
            rounded={"md"}
          >
            <FormLabel m={0}>Active Filter</FormLabel>
            <Select
              bg={"white"}
              flex={1}
              value={activeFilter}
              onChange={(e) =>
                setActiveFilter(e.target.value as keyof typeof claimFilters)
              }
            >
              <option value={"all"}>All</option>
              <option value={"requiresResponse"}>Pending</option>
              <option value={"needsReview"}>Needs Further Review</option>
              <option value={"submitted"}>Submitted</option>
              <option value={"denied"}>Denied</option>
              <option value={"paid"}>Paid</option>
            </Select>
          </HStack>
          <CHWClaimTable
            claims={claims}
            onClaimSelected={(claim) => {
              setClaim(claim);
              onToggle();
            }}
            h={"full"}
            w={"full"}
          />
        </VStack>
      )}
    </Box>
  );
};

export default CHWInvoices;

const claimFilters = {
  all: (cl: Claim) => {
    return !!cl.id;
  },
  requiresResponse: (cl: Claim) => {
    const lastStatus = getLastClaimStatus(cl);
    return (
      lastStatus === ClaimStatus.ApprovedByPatient ||
      lastStatus === ClaimStatus.ApprovedByPBCP
    );
  },
  needsReview: (cl: Claim) => {
    return getLastClaimStatus(cl) === ClaimStatus.NeedsFurtherReview;
  },
  submitted: (cl: Claim) => {
    return getLastClaimStatus(cl) === ClaimStatus.Submitted;
  },
  denied: (cl: Claim) => {
    return getLastClaimStatus(cl) === ClaimStatus.Denied;
  },
  paid: (cl: Claim) => {
    return getLastClaimStatus(cl) === ClaimStatus.ClaimPaidByInsurer;
  }
};

const getLastClaimStatus = (cl: Claim) => {
  if (cl.currentStatus) return cl.currentStatus;
  if (cl.claimStatusChanges.length === 0) return null;
  return (
    cl.claimStatusChanges[cl.claimStatusChanges.length - 1]?.status ?? null
  );
};
