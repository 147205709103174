import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  // deleteDoc,
  query,
  where,
  orderBy,
  QueryConstraint
} from "firebase/firestore";
import { Database } from "../../firebase";

import { Outreach, OutreachPurpose } from "../models/outreach";
import { GaLog } from "../utils/ga-log";

export class OutreachService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "outreaches";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // Get all outreaches for a specified client
  async getClientOutreaches(clientId: string): Promise<Outreach[]> {
    const queryRef = query(
      this._collectionReference,
      where("clientId", "==", clientId),
      orderBy("createdDate", "desc")
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        Outreach.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getOutreach(outreachId: string): Promise<Outreach> {
    try {
      const docRef = doc(this._collectionReference, outreachId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Outreach not found: ${outreachId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return Outreach.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addOutreach(outreach: Outreach): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, outreach.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateOutreach(outreach: Outreach): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, outreach.id);
      await updateDoc(docRef, outreach.toJson());
      GaLog.updateDocument(this._collectionReference.path, outreach.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // async deleteOutreach(outreach: Outreach): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, outreach.id);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, outreach.id);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }

  //* ===========================================================================
  //* Methods below are custom queries only present on web version of the service
  //* ===========================================================================
  async getOutreachesByPlaceBasedCareProvId(
    placeBasedCareProvId: string,
    outreachPurposes?: OutreachPurpose[]
  ): Promise<Outreach[]> {
    const filters: QueryConstraint[] = [
      where("placeBasedCareProvId", "==", placeBasedCareProvId)
    ];

    if (outreachPurposes && outreachPurposes?.length) {
      filters.push(where("purpose", "in", outreachPurposes));
    }

    const outreachQuery = query(this._collectionReference, ...filters);
    try {
      const outreachDocs = await getDocs(outreachQuery);
      GaLog.readCollection(`${this._collectionName}`, outreachDocs.docs.length);
      return outreachDocs.docs.map((docSnap) =>
        Outreach.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(`${this._collectionName}`, error);
      throw error;
    }
  }
}
