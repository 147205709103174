import SchedulerComponent from "../molecules/Scheduler";
import SchedulerAppointmentForm from "../molecules/SchedulerAppointmentForm";
import { Box, Select, useDisclosure } from "@chakra-ui/react";
import useWebUsers from "@/hooks/useWebUsers";
import { UserType } from "@oben-core-web/constants/core-enums";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { WebUser } from "@oben-core-web/models/web-user";
import { DateTime } from "luxon";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import PatientAppointment from "../organisms/PatientAppointment";
import { useLocation, useNavigate } from "react-router-dom";
import useCurrentUser from "@/hooks/useCurrentUser";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import {
  InternalTask,
  InternalTaskType
} from "@oben-core-web/models/internal-task";
import { Appointment } from "@oben-core-web/models/appointment";

const Appointments = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { state } = useLocation();
  const apptSetByStateRef = useRef(false);
  const [selectedWebUser, setSelectedWebUser] = useState<WebUser | null>(
    currentUser?.userType === UserType.Pharmacist ||
      currentUser?.userType === UserType.Physician
      ? currentUser
      : null
  );
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: state?.isOpen ?? false
  });
  const { isOpen: appointmentStarted, onToggle: toggleApptStarted } =
    useDisclosure();
  const [appointment, setAppointment] = useState<any>();
  const [appointmentClient, setAppointmentClient] = useState<ClientUser>();
  const webUserParams = useMemo(
    () => [UserType.Pharmacist, UserType.Physician],
    []
  );
  const { webUsers } = useWebUsers(webUserParams);

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const id = e.target.value;
      if (webUsers && webUsers.length) {
        setSelectedWebUser(webUsers.find((wu) => wu.uid === id)!);
      }
    },
    [webUsers]
  );
  useEffect(() => {
    if (state?.appointment && !apptSetByStateRef.current) {
      setAppointment(state.appointment);
      apptSetByStateRef.current = true;
    }
  }, [state?.appointment]);
  const setSelectedAppointment = useCallback(
    (appt: any) => {
      setAppointment({
        ...appt,
        startDateTime: new Date(appt.startDateTime),
        endDateTime: new Date(appt.endDateTime)
      });
      onToggle();
    },
    [onToggle]
  );
  const setSelectedDate = useCallback(
    (timeRange: { start: Date; end: Date }) => {
      const length = DateTime.fromJSDate(timeRange.end).diff(
        DateTime.fromJSDate(timeRange.start),
        "minutes"
      ).minutes;
      const emptyAppt = {
        startDateTime: timeRange.start,
        length,
        pharmacistId: selectedWebUser?.uid,
        ...(state?.clientId && state?.clientName
          ? { clientId: state.clientId, clientName: state.clientName }
          : {}),
        ...(state?.prefBarber
          ? {
              barberId: state.prefBarber.uid,
              barberName: state.prefBarber.name
            }
          : {}),
        ...(state?.prefBarbershop
          ? {
              barbershopId: state.prefBarbershop.id,
              barbershopName: state.prefBarbershop.businessName
            }
          : {})
      };
      setAppointment(emptyAppt);
      onToggle();
    },
    [onToggle, selectedWebUser, state]
  );

  const userOptions = useMemo(
    () =>
      webUsers.map((wu) => (
        <option key={wu.uid} value={wu.uid}>
          {wu.name.display}
        </option>
      )),
    [webUsers]
  );

  const startClientAppointment = async (clientId: string) => {
    // fetch client record
    const clientUserService = new ClientUserService();
    const client = await clientUserService.getClientUser(clientId);
    setAppointmentClient(client);
    // toggle off schedulerAppointmentForm modal
    onToggle();
    // toggle on patientAppointment modal
    toggleApptStarted();
  };

  const exitWizard = () => {
    // reset appointment to previously selected appointment
    setAppointment(appointment);
    onToggle(); // open up schedulerAppointmentForm modal
    setAppointmentClient(undefined); // clear appointmentClient
    toggleApptStarted(); // close PatientAppointment modal
  };

  return (
    <Box py={4} px={4} bg={"white"} h={"full"} w={"full"}>
      <Select
        mt={2}
        placeholder='Select a Pharmacist'
        onChange={handleSelect}
        value={selectedWebUser?.uid}
        className='appointments-pharmacist-select'
      >
        {userOptions}
      </Select>
      <SchedulerComponent
        selectedWebUser={selectedWebUser}
        onEventClick={setSelectedAppointment}
        onDateClick={setSelectedDate}
      />
      {appointment && (
        <SchedulerAppointmentForm
          dialogOpen={isOpen}
          appointment={appointment}
          disableFormActions={false}
          toggleDialog={() => {
            setAppointment(undefined);
            onToggle();
          }}
          onAppointmentScheduled={async (appointment: Appointment) => {
            // check if this action was completed for an internal task
            if (state?.internalTask) {
              const internalTask: InternalTask = state.internalTask;
              if (internalTask && appointment) {
                // check if client of the task is the same as the client on scheduled appt
                if (
                  internalTask.clientId === appointment.clientId &&
                  internalTask.internalTaskType ===
                    InternalTaskType.ScheduleAppointment
                ) {
                  // update the task if there is a match
                  const internalTaskService = new InternalTaskService();
                  internalTask.completionDate = new Date();
                  await internalTaskService.updateTask(internalTask);
                }
              }
            }
            if (state?.from) {
              navigate(state.from, { state: null, replace: true });
            }
          }}
          startClientAppointment={startClientAppointment}
        />
      )}
      {!!appointmentClient && appointmentStarted && !!appointment && (
        <PatientAppointment
          appointmentStarted={appointmentStarted}
          toggleApptStarted={toggleApptStarted}
          clientUser={appointmentClient}
          appointment={appointment}
          exitWizard={exitWizard}
        />
      )}
    </Box>
  );
};

export default Appointments;
