import useClientUserSubscription from "@/hooks/useClientUserSubscription";
import useTaskBase from "@/hooks/useTaskBase";
import useTasks from "@/hooks/useTasks";
import {
  Select,
  Table,
  Td,
  Thead,
  Tr,
  Tbody,
  Input,
  HStack,
  Button,
  useToast,
  Spinner
} from "@chakra-ui/react";
import { FreqPeriod, UserType } from "@oben-core-web/constants/core-enums";
import {
  ClientTask,
  ClientTaskCategory,
  ClientTaskType
} from "@oben-core-web/models/client-task";
import {
  // Controller,
  useForm
} from "react-hook-form";
import _ from "lodash";
import {
  TaskStatus,
  TaskStatusChange
} from "@oben-core-web/models/task-status-change";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { UserName } from "@oben-core-web/models/user-name";
import {
  // AppointmentStatus,
  AppointmentStatusChange
} from "@oben-core-web/models/appointment-status-change";
// import { Barbershop } from "@oben-core-web/models/barbershop";
// import IndexSearch from "./IndexSearch";
import { calculateExpectedCount } from "@/lib/clientTasks";
// import { Appointment } from "@oben-core-web/models/appointment";
// import useBarbershops from "@/hooks/useBarbershops";
import { DateTime } from "luxon";
import { ASCVDRiskUtils } from "@oben-core-web/utils/ascvd-risk-utils";
import {
  useEffect,
  useState
  // useState
} from "react";
import { WebUser } from "@oben-core-web/models/web-user";
// import { BarbershopService } from "@oben-core-web/services/barbershop-service";
// import useBarber from "@/hooks/useBarber";
import {
  InternalTask,
  InternalTaskType,
  TaskCreatorType
} from "@oben-core-web/models/internal-task";
import useWebUsers from "@/hooks/useWebUsers";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";

interface ICPWizardStep7 {
  carePlanId: string;
  clientId: string;
  pharmacistId: string;
  currentUser: WebUser;
  internalTaskId?: string;
  setInternalTaskId: React.Dispatch<React.SetStateAction<string>>;
  onSubmit?: () => void;
  onCancel?: () => void;
}

interface ITaskForm {
  taskBaseId: string;
  modelId: string;
  name: string;
  date: string;
  category: ClientTaskCategory;
  description: string;
  frequencyCount: number;
  frequencyPeriod: FreqPeriod;
  // duration: number;
}

interface IApptForm {
  barberId: string;
  barberName: UserName;
  barbershopId: string;
  barbershopName: string;
  pharmacistId: string;
  pharmacistName: UserName;
  date: string;
  length: number;
  billableEventId: string;
  statusChanges: AppointmentStatusChange[];
}

interface IInternalTaskForm {
  id: string;
  placeBasedCareProvId: string;
  internalTaskType: InternalTaskType.ScheduleAppointment;
  name: string;
  description: string;
  clientId: string;
  assigneeId: string;
  creatorId: string;
  creatorType: TaskCreatorType.WebUser;
  createdDate: Date;
  dueDate: Date;
  completionDate: Date | null;
  timeBetweenAppts: number | null;
}

interface ICPWizardStep7Form {
  atHomeMonitoring: ITaskForm;
  primaryCareFollowUp: ITaskForm;
  nextAppt: IApptForm;
  internalTask: IInternalTaskForm;
}

const CPWizardStep7 = ({
  carePlanId,
  clientId,
  pharmacistId,
  currentUser,
  internalTaskId,
  setInternalTaskId,
  onSubmit,
  onCancel
}: ICPWizardStep7) => {
  const toast = useToast();
  const { clientUser } = useClientUserSubscription(clientId);
  // const { barber } = useBarber({
  //   uid: clientUser?.prefBarberId ?? "",
  //   initialFetch: true
  // });
  // const { barbershops } = useBarbershops();
  const { webUsers } = useWebUsers([UserType.ProgramManager]);
  const [internalTask, setInternalTask] = useState<InternalTask>();
  const { taskBases, loading: taskBasesLoading } = useTaskBase({
    placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? ""
  });
  const clientTasks = useTasks({ clientId, carePlanId });
  const atHomeMonitoringTask = clientTasks.find(
    (t) =>
      t.carePlanId === carePlanId &&
      t.taskType === ClientTaskType.HomeBPMonitoring
  );
  const pcpFollowUpTask = clientTasks.find(
    (t) =>
      t.carePlanId === carePlanId &&
      t.taskType === ClientTaskType.HealthcareAction
  );
  const atHomeMonitoringTaskBase = taskBases.find(
    (tb) => tb.taskType === ClientTaskType.HomeBPMonitoring
  );
  const pcpFollowUpTaskBase = taskBases.find(
    (tb) => tb.taskType === ClientTaskType.HealthcareAction
  );
  const { register, handleSubmit, formState, setError, setValue } =
    useForm<ICPWizardStep7Form>({
      defaultValues: {
        atHomeMonitoring: {
          taskBaseId:
            atHomeMonitoringTask?.clientTaskBaseId ??
            atHomeMonitoringTaskBase?.id ??
            "",
          modelId: atHomeMonitoringTask?.modelId ?? "",
          name:
            atHomeMonitoringTask?.name ?? atHomeMonitoringTaskBase?.name ?? "",
          date: (atHomeMonitoringTask?.dueDate ?? new Date()).toDateString(),
          category:
            atHomeMonitoringTask?.taskCategory ??
            atHomeMonitoringTaskBase?.taskCategory ??
            ClientTaskCategory.Unknown,
          description:
            atHomeMonitoringTask?.description ??
            atHomeMonitoringTaskBase?.description ??
            "",
          frequencyCount:
            atHomeMonitoringTask?.frequencyCount ??
            atHomeMonitoringTaskBase?.defaultFreqCount ??
            1,
          frequencyPeriod:
            atHomeMonitoringTask?.frequencyPeriod ??
            atHomeMonitoringTaskBase?.defaultFreqPeriod ??
            FreqPeriod.Daily
        },
        primaryCareFollowUp: {
          taskBaseId:
            pcpFollowUpTask?.clientTaskBaseId ?? pcpFollowUpTaskBase?.id ?? "",
          modelId: pcpFollowUpTask?.modelId ?? "",
          name: pcpFollowUpTask?.name ?? pcpFollowUpTaskBase?.name ?? "",
          date: (pcpFollowUpTask?.dueDate ?? new Date()).toDateString(),
          category:
            pcpFollowUpTask?.taskCategory ??
            pcpFollowUpTaskBase?.taskCategory ??
            ClientTaskCategory.Unknown,
          description:
            pcpFollowUpTask?.description ??
            pcpFollowUpTaskBase?.description ??
            "",
          frequencyCount:
            pcpFollowUpTask?.frequencyCount ??
            pcpFollowUpTaskBase?.defaultFreqCount ??
            1,
          frequencyPeriod:
            pcpFollowUpTask?.frequencyPeriod ??
            pcpFollowUpTaskBase?.defaultFreqPeriod ??
            FreqPeriod.Daily
        }, // TODO: select most recent followUp
        // nextAppt: {
        //   barberId: clientUser?.prefBarberId ?? "",
        //   barberName:
        //     barber?.name ?? ({ first: "", last: "", display: "" } as UserName),
        //   barbershopId: clientUser?.prefBarbershopId ?? "",
        //   barbershopName:
        //     barbershops.find((b) => b.id === clientUser?.prefBarberId)
        //       ?.businessName ?? "",
        //   pharmacistId,
        //   pharmacistName:
        //     currentUser?.name ??
        //     ({ first: "", last: "", display: "" } as UserName),
        //   date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
        //   length: 30,
        //   billableEventId: "",
        //   statusChanges: [
        //     new AppointmentStatusChange({
        //       status: AppointmentStatus.New,
        //       date: new Date(),
        //       details: "",
        //       editorId: currentUser.uid,
        //       editorType: currentUser.userType
        //     })
        //   ]
        // },
        internalTask: {
          id: "",
          placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
          internalTaskType: InternalTaskType.ScheduleAppointment,
          name: "Schedule Follow up",
          description: `Schedule Follow up appointment for ${
            clientUser?.name.display ?? "Patient"
          }`,
          clientId: clientUser?.uid,
          assigneeId: "",
          creatorId: currentUser.uid,
          creatorType: TaskCreatorType.WebUser,
          createdDate: new Date(),
          dueDate: new Date(),
          completionDate: null,
          timeBetweenAppts: null
        }
      }
    });

  useEffect(() => {
    const fetchPrefillItems = async () => {
      if (atHomeMonitoringTask || atHomeMonitoringTaskBase) {
        setValue("atHomeMonitoring", {
          taskBaseId:
            atHomeMonitoringTask?.clientTaskBaseId ||
            atHomeMonitoringTaskBase?.id ||
            "",
          modelId: atHomeMonitoringTask?.modelId || "",
          name:
            atHomeMonitoringTask?.name || atHomeMonitoringTaskBase?.name || "",
          date: DateTime.fromJSDate(
            atHomeMonitoringTask?.dueDate || new Date()
          ).toFormat("yyyy-MM-dd"),
          category:
            atHomeMonitoringTask?.taskCategory ||
            atHomeMonitoringTaskBase?.taskCategory ||
            ClientTaskCategory.Unknown,
          description:
            atHomeMonitoringTask?.description ||
            atHomeMonitoringTaskBase?.description ||
            "",
          frequencyCount:
            atHomeMonitoringTask?.frequencyCount ||
            atHomeMonitoringTaskBase?.defaultFreqCount ||
            1,
          frequencyPeriod:
            atHomeMonitoringTask?.frequencyPeriod ||
            atHomeMonitoringTaskBase?.defaultFreqPeriod ||
            FreqPeriod.Daily
        });
      }
      if (pcpFollowUpTask || pcpFollowUpTaskBase) {
        setValue("primaryCareFollowUp", {
          taskBaseId:
            pcpFollowUpTask?.clientTaskBaseId || pcpFollowUpTaskBase?.id || "",
          modelId: pcpFollowUpTask?.modelId || "",
          name: pcpFollowUpTask?.name || pcpFollowUpTaskBase?.name || "",
          date: DateTime.fromJSDate(
            pcpFollowUpTask?.dueDate || new Date()
          ).toFormat("yyyy-MM-dd"),
          category:
            pcpFollowUpTask?.taskCategory ||
            pcpFollowUpTaskBase?.taskCategory ||
            ClientTaskCategory.Unknown,
          description:
            pcpFollowUpTask?.description ||
            pcpFollowUpTaskBase?.description ||
            "",
          frequencyCount:
            pcpFollowUpTask?.frequencyCount ||
            pcpFollowUpTaskBase?.defaultFreqCount ||
            1,
          frequencyPeriod:
            pcpFollowUpTask?.frequencyPeriod ||
            pcpFollowUpTaskBase?.defaultFreqPeriod ||
            FreqPeriod.Daily
        });
      }
      if (internalTaskId && !internalTask) {
        const internalTaskService = new InternalTaskService();
        await internalTaskService.getTask(internalTaskId).then((task) => {
          setInternalTask(task);
          setValue("internalTask", {
            id: task.id,
            placeBasedCareProvId: task.placeBasedCareProvId,
            internalTaskType: InternalTaskType.ScheduleAppointment,
            name: task.name,
            description: task.description,
            clientId: task.clientId,
            assigneeId: task.assigneeId,
            creatorId: task.creatorId,
            creatorType: TaskCreatorType.WebUser,
            createdDate: task.createdDate,
            dueDate: task.dueDate,
            completionDate: null,
            timeBetweenAppts: Math.ceil(
              DateTime.fromJSDate(task.dueDate).diffNow("weeks").as("weeks")
            )
          });
        });
      }
    };

    fetchPrefillItems();
  }, [
    atHomeMonitoringTaskBase,
    pcpFollowUpTaskBase,
    atHomeMonitoringTask,
    pcpFollowUpTask,
    internalTaskId,
    internalTask,
    // reset,
    setValue
  ]);
  formState.dirtyFields;
  const submissionHandler = async (data: ICPWizardStep7Form) => {
    const {
      atHomeMonitoring,
      primaryCareFollowUp,
      // nextAppt,
      internalTask
    } = data;
    // only work with tasks that were dirtied
    const dirtyFields = formState.dirtyFields;

    let atHomeMonitoringTask: ClientTask | undefined = undefined;
    let primaryCareFollowUpTask: ClientTask | undefined = undefined;
    // let nextAppointment: Appointment | undefined = undefined;
    let followUpTask: InternalTask | undefined = undefined;

    if (dirtyFields.atHomeMonitoring) {
      const homeBpTaskBase = taskBases.find(
        (t) => t.taskType === ClientTaskType.HomeBPMonitoring
      );
      atHomeMonitoringTask = new ClientTask({
        modelId: atHomeMonitoring.modelId ?? "",
        clientId,
        carePlanId,
        clientTaskBaseId: homeBpTaskBase!.id,
        taskType: ClientTaskType.HomeBPMonitoring,
        taskCategory: homeBpTaskBase!.taskCategory,
        name: homeBpTaskBase!.name,
        description: homeBpTaskBase!.description,
        frequencyCount: atHomeMonitoring.frequencyCount,
        frequencyPeriod: atHomeMonitoring.frequencyPeriod,
        placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
        dueDate: DateTime.fromISO(atHomeMonitoring.date).toUTC().toJSDate(),
        createdDate: new Date(),
        creatorId: pharmacistId,
        creatorType: UserType.Pharmacist,
        expectedCount: calculateExpectedCount(
          new Date(),
          new Date(atHomeMonitoring.date),
          atHomeMonitoring.frequencyCount,
          atHomeMonitoring.frequencyPeriod
        ),
        statusChanges: [
          new TaskStatusChange({
            date: new Date(),
            status: atHomeMonitoring.modelId
              ? TaskStatus.InProgress
              : TaskStatus.New
          })
        ],
        taskCompletionDates: []
      });
    }

    if (dirtyFields.primaryCareFollowUp) {
      const followUpTaskBase = taskBases.find(
        (t) => t.taskType === ClientTaskType.HealthcareAction
      );
      primaryCareFollowUpTask = new ClientTask({
        modelId: primaryCareFollowUp?.modelId ?? "",
        clientId,
        carePlanId,
        clientTaskBaseId: followUpTaskBase!.id,
        taskCategory: followUpTaskBase!.taskCategory,
        taskType: ClientTaskType.HealthcareAction,
        name: followUpTaskBase!.name,
        description: followUpTaskBase!.description,
        frequencyCount: primaryCareFollowUp.frequencyCount,
        frequencyPeriod: primaryCareFollowUp.frequencyPeriod,
        placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
        dueDate: DateTime.fromISO(primaryCareFollowUp.date).toUTC().toJSDate(),
        createdDate: new Date(),
        creatorId: pharmacistId,
        creatorType: UserType.Pharmacist,
        expectedCount: calculateExpectedCount(
          new Date(),
          new Date(primaryCareFollowUp.date),
          primaryCareFollowUp.frequencyCount,
          primaryCareFollowUp.frequencyPeriod
        ),
        statusChanges: [
          new TaskStatusChange({
            date: new Date(),
            status: primaryCareFollowUp.modelId
              ? TaskStatus.InProgress
              : TaskStatus.New
          })
        ],
        taskCompletionDates: []
      });
    }

    if (dirtyFields.internalTask) {
      if (!internalTask.timeBetweenAppts) {
        setError("internalTask.timeBetweenAppts", {
          message: "You must make a selection"
        });
        toast({
          status: "error",
          description: "Must select time between appointments"
        });
        return;
      }
      if (!internalTask.assigneeId) {
        setError("internalTask.assigneeId", {
          message: "You must make a selection"
        });
        toast({
          status: "error",
          description: "Must select a team member to assign this to"
        });
        return;
      }
      if (internalTask.id) {
        followUpTask = new InternalTask({
          ...internalTask,
          clientId,
          dueDate: DateTime.now()
            .plus({ weeks: internalTask.timeBetweenAppts })
            .toJSDate()
        });
      } else {
        followUpTask = new InternalTask({
          id: "",
          assigneeId: internalTask.assigneeId,
          clientId,
          completionDate: null,
          createdDate: new Date(),
          creatorId: currentUser.uid,
          creatorType: TaskCreatorType.WebUser,
          description: `Schedule Follow up for ${clientUser?.name.display}`,
          name: "Schedule Follow-up",
          placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
          internalTaskType: InternalTaskType.ScheduleAppointment,
          dueDate: DateTime.now()
            .plus({ weeks: internalTask.timeBetweenAppts })
            .toJSDate()
        });
      }
    }
    // if (dirtyFields.nextAppt) {
    //   if (new Date(nextAppt.date) < new Date()) {
    //     toast({
    //       status: "error",
    //       description: "Cannot create an appointment in the past"
    //     });
    //     return;
    //   }
    //   nextAppointment = new Appointment({
    //     ...nextAppt,
    //     date: new Date(nextAppt.date),
    //     id: "",
    //     clientId,
    //     clientName: clientUser!.name,
    //     barberName: UserName.fromMap(data.nextAppt.barberName),
    //     currentStatus: AppointmentStatus.New,
    //     statusChanges: [
    //       new AppointmentStatusChange({
    //         status: AppointmentStatus.New,
    //         date: new Date(),
    //         editorType: currentUser.userType,
    //         editorId: currentUser.uid,
    //         details: "Appointment created"
    //       })
    //     ],
    //     outreachId: ""
    //   });
    // }
    const ascvdScore = await ASCVDRiskUtils.createASCVDRiskObject(clientId);
    await CarePlanTransactionService.saveASCVDRiskData({
      ascvdRisk: ascvdScore
    });
    // submit dirtied rows to transaction
    await CarePlanTransactionService.saveFollowUpAndMonitoringData({
      clientId,
      carePlanId,
      tasks: _.compact([primaryCareFollowUpTask, atHomeMonitoringTask]),
      // appointment: nextAppointment
      followUpTask
    })
      .then((result) => {
        if (result) {
          const { internalTaskId } = result;
          if (internalTaskId) {
            setInternalTaskId(internalTaskId);
          }
        }
        onSubmit && onSubmit();
      })
      .catch((e) => {
        console.log({ CarePlanStep7Error: e });
        toast({
          status: "error",
          description: "Error saving follow up and monitoring data"
        });
      });
  };
  return (
    <>
      {taskBasesLoading ? (
        <Spinner />
      ) : (
        clientUser && (
          <form
            style={{
              minHeight: "30rem",
              display: "flex",
              position: "relative",
              flexDirection: "column"
            }}
            onSubmit={handleSubmit(submissionHandler)}
          >
            <Table size='xs' my={"1rem"}>
              <Thead>
                <Tr>
                  <Td w={"18%"}></Td>
                  <Td>Repititions</Td>
                  <Td>Period</Td>
                  <Td>Due Date</Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>At home monitoring</Td>
                  <Td>
                    <Input
                      type='number'
                      min={0}
                      placeholder='How many times?'
                      {...register(`atHomeMonitoring.frequencyCount`)}
                    />
                  </Td>
                  <Td>
                    <Select {...register(`atHomeMonitoring.frequencyPeriod`)}>
                      <option value={"Daily"}>Daily</option>
                      <option value={"Weekly"}>Weekly</option>
                    </Select>
                  </Td>
                  <Td>
                    <Input type='date' {...register(`atHomeMonitoring.date`)} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Table size='xs' my={"1rem"}>
              <Thead>
                <Tr>
                  <Td w={"18%"}></Td>
                  <Td w={"4.5%"}>Due Date</Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Primary Care Follow up</Td>
                  <Td>
                    <Input
                      type='date'
                      {...register(`primaryCareFollowUp.date`)}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Table size='xs' my={"1rem"}>
              <Thead>
                <Tr>
                  <Td w={"18%"}></Td>
                  <Td flex={1}>Assignee</Td>
                  <Td flex={1}>Schedule in</Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Next Appointment</Td>
                  <Td>
                    <Select
                      placeholder='Make a selection'
                      {...register("internalTask.assigneeId")}
                    >
                      {webUsers.map((wu) => (
                        <option value={wu.uid}>{wu.name.display}</option>
                      ))}
                    </Select>
                  </Td>
                  <Td>
                    <Select
                      placeholder='Make a selection'
                      {...register("internalTask.timeBetweenAppts")}
                    >
                      <option value={2}>2 Weeks</option>
                      <option value={4}>4 Weeks</option>
                      <option value={6}>6 Weeks</option>
                      <option value={8}>8 Weeks</option>
                    </Select>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            {/* <Table size='xs' my={"1rem"}>
              <Thead>
                <Tr>
                  <Td w={"18%"}></Td>
                  <Td>Date</Td>
                  <Td w={"12%"}>Duration</Td>
                  <Td>Barber</Td>
                  <Td>Barbershop</Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Next Appointment</Td>
                  <Td>
                    <Input
                      type='datetime-local'
                      {...register(`nextAppt.date`)}
                    />
                  </Td>
                  <Td>
                    <Input
                      type='number'
                      min={0}
                      placeholder='Minutes'
                      {...register(`nextAppt.length`)}
                    />
                  </Td>
                  <Td>
                    <IndexSearch
                      w={"full"}
                      searchIndex='barbers'
                      // inputLabel="Barber"
                      prefilledText={barber?.name.display ?? ""}
                      placeholderText='Search by barber name'
                      transformItems={(items: any[]) => {
                        return items.map((item) => ({
                          ...item,
                          label: item.name.first + " " + item.name.last,
                          name: item.name,
                          id: item.objectID.split("/")[1] ?? item.objectID,
                          ...(item.dob
                            ? {
                                dob: DateTime.fromJSDate(new Date(item.dob))
                                  .toUTC()
                                  .toFormat("DD")
                              }
                            : {}),
                          email: item.email
                        }));
                      }}
                      onResultClick={async ({
                        id,
                        name,
                        barbershopIds
                      }: any) => {
                        setValue("nextAppt.barberId", id, {
                          shouldDirty: true
                        });
                        setValue("nextAppt.barberName", name, {
                          shouldDirty: true
                        });
                        const barbershopService = new BarbershopService();
                        const barbershops =
                          await barbershopService.getBarbershops(barbershopIds);
                        setWorkplaceOptions(barbershops);
                      }}
                      onClearSelection={() => setWorkplaceOptions([])}
                    />
                  </Td>
                  <Td>
                    <Controller
                      name='nextAppt.barbershopId'
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder='Select a barbershop'
                          {...field}
                          onChange={(e) => {
                            const selectedBarbershop = barbershops.find(
                              (b: Barbershop) => b.id === e.target.value
                            );
                            if (selectedBarbershop) {
                              setValue(
                                `nextAppt.barbershopName`,
                                selectedBarbershop.businessName,
                                { shouldDirty: true }
                              );
                            }
                            field.onChange(e);
                          }}
                          isDisabled={workplaceOptions.length === 0}
                        >
                          {workplaceOptions?.map((b: Barbershop) => (
                            <option key={`barbershop-${b.id}`} value={b.id}>
                              {b.businessName}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table> */}
            <HStack
              mt={"2rem"}
              w={"full"}
              justifyContent={"space-between"}
              pos={"absolute"}
              bottom={0}
            >
              {onCancel && <Button onClick={onCancel}>Previous</Button>}
              {onSubmit && <Button type='submit'>Next</Button>}
            </HStack>
          </form>
        )
      )}
    </>
  );
};

export default CPWizardStep7;
