import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { PrimaryCareProv } from '../models/primary-care-prov';
import { GaLog } from '../utils/ga-log';

export class PrimaryCareProvService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'primaryCareProvs';
    this._collectionReference = collection(Database, this._collectionName);
  }

  async getAllPrimaryCareProvs(): Promise<PrimaryCareProv[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => PrimaryCareProv.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a PrimaryCareProv exists
  async validatePrimaryCareProv(primaryCareProvId: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, primaryCareProvId);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPrimaryCareProv(primaryCareProvId: string): Promise<PrimaryCareProv> {
    try {
      const docRef = doc(this._collectionReference, primaryCareProvId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Primare Care Provider not found: ${primaryCareProvId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return PrimaryCareProv.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addPrimaryCareProv(primaryCareProv: PrimaryCareProv): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, primaryCareProv.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updatePrimaryCareProv(primaryCareProv: PrimaryCareProv): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, primaryCareProv.id);
      await updateDoc(docRef, primaryCareProv.toJson());
      GaLog.updateDocument(this._collectionReference.path, primaryCareProv.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // PrimaryCareProvs can only be updated, not deleted
  // async deletePrimaryCareProv(primaryCareProvId: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, primaryCareProvId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, primaryCareProvId););
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
