import { VStack, Text, HStack, Button, Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import NavLink from "../atoms/NavLink";
import { FiCalendar, FiHome, FiUsers } from "react-icons/fi";
import ObenLogo from "@/assets/ObenLogo";
import useTourSteps from "@/hooks/useTourSteps";
import { useTourContext } from "@/hooks/useTourContext";
import { useNavigate } from "react-router-dom";

const NavSidebar = ({
  isExpanded,
  toggleExpansion
}: {
  isExpanded: boolean;
  toggleExpansion: () => void;
}) => {
  const [showText, setShowText] = useState(isExpanded);
  const { getTourSteps } = useTourSteps();
  const { startTour } = useTourContext();
  const navigate = useNavigate();

  // Delay the text rendering for smoother expansion transition
  useEffect(() => {
    if (isExpanded) {
      const timer = setTimeout(() => setShowText(true), 300); // Delay text by 300ms
      return () => clearTimeout(timer);
    } else {
      setShowText(false); // Hide text immediately on collapse
    }
  }, [isExpanded]);

  return (
    <VStack
      className='nav-sidebar'
      w={isExpanded ? "15rem" : "4rem"}
      transition='width 0.4s ease-in-out'
      h='full'
      bg='white'
      align='flex-start'
      pos='relative'
    >
      {/* Header Section with Logo */}
      <HStack
        w='full'
        h={"5rem"}
        py={4}
        px={isExpanded ? 4 : 2}
        justifyContent={isExpanded ? "flex-start" : "center"}
        transition='opacity 0.4s ease-in-out'
      >
        <Box onClick={toggleExpansion} cursor={"pointer"}>
          <ObenLogo height={30} width={30} />
        </Box>
        {showText && (
          <Text
            as='h1'
            fontSize='larger'
            textAlign='center'
            // lineHeight='3rem'
            ml={2}
          >
            Oben Health
          </Text>
        )}
      </HStack>

      {/* Navigation Links */}
      <VStack
        w='full'
        h='full'
        align='flex-start'
        px={isExpanded ? 4 : 2}
        spacing={3}
      >
        <NavLink to='/' icon={FiHome} label={showText ? "Home" : ""} />
        <NavLink
          to='/patients'
          icon={FiUsers}
          label={showText ? "Patients" : ""}
        />
        <NavLink
          to='/appointments'
          icon={FiCalendar}
          label={showText ? "Appointments" : ""}
        />
      </VStack>

      {/* Take a Tour Button */}
      {showText && (
        <Button
          pos='absolute'
          bottom={4}
          variant='outline'
          size='xs'
          w='14rem'
          colorScheme='red'
          onClick={() => {
            navigate("/");
            startTour(
              getTourSteps({
                stepSetName: "full"
              })
            );
          }}
        >
          Take a tour
        </Button>
      )}
    </VStack>
  );
};

export default NavSidebar;
