import {
  Center,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import AppHeader from "./components/organisms/AppHeader";
import NavSidebar from "./components/organisms/NavSidebar";
import { Outlet } from "react-router-dom";
import useInactivityTimer from "./hooks/useInactivityTimer";
import useCurrentUser from "./hooks/useCurrentUser";
import ChangePasswordForm from "./components/molecules/ChangePasswordForm";
import { useAppContext } from "./hooks/useAppContext";
import MaintenanceAlert from "./components/molecules/MaintenanceAlert";
import useMaintenanceListener from "./hooks/useMaintenanceListener";
// import { useAuthContext } from "./hooks/useAuthContext";
// import PharmacistWelcomeModal from "./components/molecules/PharmacistWelcomeModal";
import Joyride from "./components/organisms/Joyride";

// Stretch TODO: view barbers and their payouts
function App() {
  useInactivityTimer();
  useMaintenanceListener();
  const { currentUser } = useCurrentUser();
  const {
    state: { inMaintenanceMode }
  } = useAppContext();
  const { isOpen, onToggle } = useDisclosure();
  // const {
  //   setFirstTimeLogin,
  //   state: { isFirstTimeLogin }
  // } = useAuthContext();
  if (inMaintenanceMode) {
    return (
      <Center h={"100vh"} w={"full"} display={"flex"} flexDirection={"column"}>
        <Heading>Maintenance in progress</Heading>
        <Text mt={4}>Please check back later</Text>
      </Center>
    );
  }
  if (!currentUser) {
    return (
      <Center h={"full"} w={"full"}>
        <Spinner />
      </Center>
    );
  }
  return (
    <>
      {currentUser.pwdNeedsReset ? (
        <ChangePasswordForm />
      ) : (
        <Grid
          templateAreas={`"nav header"
      "nav main"`}
          gridTemplateRows={"4.5rem 1fr"}
          gridTemplateColumns={`${isOpen ? "15rem" : "4rem"} 1fr`}
          bg={"gray.50"}
          minH='100vh'
          gap='1'
          fontWeight='bold'
          p={"2px"}
          transition='grid-template-columns 0.5s ease-in-out, padding 0.3s ease-in-out'
        >
          <GridItem area={"nav"} bg={"white"}>
            <NavSidebar isExpanded={isOpen} toggleExpansion={onToggle} />
          </GridItem>
          <GridItem area={"header"} mx={4} my={2}>
            <AppHeader />
          </GridItem>
          <GridItem area={"main"} p={4}>
            <main style={{ height: "100%", position: "relative" }}>
              {/* {isFirstTimeLogin && "FIRSTT TIME"}
              <PharmacistWelcomeModal
                isOpen={isFirstTimeLogin}
                onClose={() => setFirstTimeLogin(false)}
              /> */}
              <MaintenanceAlert />
              {/* <AppTour /> */}
              <Joyride />
              <Outlet /> {/* Routed components will be rendered here */}
              <div
                className='center'
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "40%",
                  visibility: "hidden"
                }}
              ></div>
            </main>
          </GridItem>
        </Grid>
      )}
    </>
  );
}

export default App;
