import { Card } from "@chakra-ui/react";
import React from "react";
import Chart from "react-apexcharts";

interface IPieChart {
  title?: string;
  labels: string[];
  series: number[];
}

const SimplePieChart = ({ labels, series, title }: IPieChart) => {
  // Define your chart data
  const chartOptions: ApexCharts.ApexOptions = {
    labels,
    // colors: ["#008FFB", "#FF4560"], // Customize colors for each category
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 300
    //       },
    //       legend: {
    //         position: "bottom"
    //       }
    //     }
    //   }
    // ],
    legend: {
      // show: false,
      position: "bottom"
    }
  };

  const chartSeries = series; // Sample data: e.g., 55% male, 45% female

  return (
    <Card
      className='pie-chart'
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      h={"full"}
      p={4}
    >
      {title}
      <Chart
        options={chartOptions}
        series={chartSeries}
        type='pie'
        width='225'
        // height='400'
      />
    </Card>
  );
};

export default SimplePieChart;
