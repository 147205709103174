import { object, string, date } from "yup";

const recommendationLetterSchema = object({
  details: string().required(),
  clientId: string().required(),
  supervisingPhysId: string().required(),
  placeBasedCareProvId: string().required(),
  payerId: string().required(),
  createdDate: date(),
  lastDownloadDate: date().nullable(),
  attestation: string().required("Sign your name here")
});

export default recommendationLetterSchema;
