import { DocumentSnapshot } from "firebase/firestore";

import { InternalTaskService } from "../services/internal-task-service";

export enum InternalTaskType {
  Unknown = "Unknown",
  ScheduleAppointment = "ScheduleAppointment",
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class InternalTaskTypeHelper {
  static valueOf(searchString: string | null): InternalTaskType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(InternalTaskType).includes(
      searchString as InternalTaskType
    ) //
      ? (searchString as InternalTaskType)
      : null;
  }
}

export enum TaskCreatorType {
  Unknown = "Unknown",
  System = "System",
  WebUser = "WebUser",
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class TaskCreatorTypeHelper {
  static valueOf(searchString: string | null): TaskCreatorType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(TaskCreatorType).includes(
      searchString as TaskCreatorType
    ) //
      ? (searchString as TaskCreatorType)
      : null;
  }
}

export interface IInternalTaskData {
  id: string;
  placeBasedCareProvId: string;
  internalTaskType: InternalTaskType;
  name: string;
  description: string;
  clientId: string;
  assigneeId: string;
  creatorId: string;
  creatorType: TaskCreatorType;
  createdDate: Date;
  dueDate: Date;
  completionDate: Date | null;
}

export class InternalTask {
  id: string;
  placeBasedCareProvId: string;
  internalTaskType: InternalTaskType;
  name: string;
  description: string;
  clientId: string;
  assigneeId: string;
  creatorId: string;
  creatorType: TaskCreatorType;
  createdDate: Date;
  dueDate: Date;
  completionDate: Date | null;

  constructor({
    id,
    placeBasedCareProvId,
    internalTaskType,
    name,
    description,
    clientId,
    assigneeId,
    creatorId,
    creatorType,
    createdDate,
    dueDate,
    completionDate,
  }: IInternalTaskData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.internalTaskType = internalTaskType;
    this.name = name;
    this.description = description;
    this.clientId = clientId;
    this.assigneeId = assigneeId;
    this.creatorId = creatorId;
    this.creatorType = creatorType;
    this.createdDate = createdDate;
    this.dueDate = dueDate;
    this.completionDate = completionDate;
  }

  get isComplete(): boolean {
    return this.completionDate != null;
  }

  async updateDb(): Promise<void> {
    const db = new InternalTaskService();
    await db.updateTask(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): InternalTask {
    const data = docSnap.data() as { [key: string]: any };
    return InternalTask.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): InternalTask {
    return new InternalTask({
      id: id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      internalTaskType: InternalTaskTypeHelper.valueOf(data["internalTaskType"] ?? "") ?? InternalTaskType.Unknown,
      name: data["name"] ?? "",
      description: data["description"] ?? "",
      clientId: data["clientId"] ?? "",
      assigneeId: data["assigneeId"] ?? "",
      creatorId: data["creatorId"],
      creatorType: TaskCreatorTypeHelper.valueOf(data["creatorType"] ?? "") ?? TaskCreatorType.Unknown,
      createdDate: data["createdDate"]
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1),
      dueDate: data["dueDate"]
        ? new Date(data["dueDate"])
        : new Date(1, 0, 1),
      completionDate: data["completionDate"]
        ? new Date(data["completionDate"])
        : null,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // id: this.id, // not necessary to save ID (it is already part of the Firestore document)
      placeBasedCareProvId: this.placeBasedCareProvId,
      internalTaskType: this.internalTaskType,
      name: this.name,
      description: this.description,
      clientId: this.clientId,
      assigneeId: this.assigneeId,
      creatorId: this.creatorId,
      creatorType: this.creatorType,
      createdDate: this.createdDate.toISOString(),
      dueDate: this.dueDate.toISOString(),
      completionDate: this.completionDate?.toISOString() ?? null,

    };
  }
}
