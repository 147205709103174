export const parsePhoneNumber = (phoneNumber: string) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Match and reformat the cleaned phone number
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Format to (XXX) XXX-XXXX
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // If no match, return the input as-is
  return phoneNumber;
};
