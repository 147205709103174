import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import useProgramManagerQueries from "@/hooks/useProgramManagerQueries";
import {
  Card,
  HStack,
  VStack,
  Text,
  Heading,
  Tooltip,
  Center,
  Spinner
} from "@chakra-ui/react";
import { BillableItem } from "@oben-core-web/models/billable-item";
import { startCase } from "lodash";
import { useEffect, useState } from "react";

// interface IDashboardPendingPayments {
//   start?: Date;
//   end?: Date;
// }

const DashboardPendingPayments = () =>
  // {
  // start = null,
  // end = null
  // }: IDashboardPendingPayments
  {
    const { placeBasedCareProvider } = usePlaceBasedCareProvider();
    const { getBillableItemsByApptStatus } = useProgramManagerQueries(
      placeBasedCareProvider?.id ?? ""
    );
    const [data, setData] = useState<
      Record<
        string,
        Record<
          string,
          {
            billableItems: (BillableItem & { billStatus: string })[];
            total: number;
          }
        >
      >
    >();

    useEffect(() => {
      const fetchData = async () => {
        const billableItemData = await getBillableItemsByApptStatus();
        setData(billableItemData);
      };
      fetchData();
    }, [getBillableItemsByApptStatus]);

    return (
      <Card
        w={"60%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        p={5}
      >
        <Heading as={"h2"} fontSize={"lg"} mb={2}>
          Payments
        </Heading>
        {data ? (
          Object.entries(data).length > 0 ? (
            Object.entries(data).map(([key, value]) => (
              <VStack
                key={`dpp-p-status-${key}`}
                w={"full"}
                alignItems={"flex-start"}
                // border={"1px solid"}
                spacing={0}
                my={1}
              >
                <HStack
                  w={"full"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text>{startCase(key)}</Text>
                  <Tooltip
                    label={`Subtotal of ${key} payments`}
                    placement='left'
                    hasArrow
                  >
                    <Text>
                      $
                      {Object.values(value).reduce((a, c) => a + c.total, 0) /
                        100}
                    </Text>
                  </Tooltip>
                </HStack>
                {Object.entries(value).map(([subKey, subValue]) => {
                  return (
                    <HStack
                      key={`dpp-a-status-${key}`}
                      w={"full"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      fontWeight={"normal"}
                    >
                      <Text>{subKey}</Text>
                      <Text>${subValue.total / 100}</Text>
                    </HStack>
                  );
                })}
              </VStack>
            ))
          ) : (
            <Center>
              <Text as={"i"} fontWeight={"normal"}>
                No Data
              </Text>
            </Center>
          )
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </Card>
    );
  };

export default DashboardPendingPayments;
