import usePatients from "@/hooks/usePatients";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { Card, Heading, HStack, Text } from "@chakra-ui/react";
import {
  LuUsers,
  LuUserPlus,
  LuInbox,
  LuDownload,
  LuActivity
} from "react-icons/lu";

const DashboardEnrolledPatients = () => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { clientUsers } = usePatients(placeBasedCareProvider?.id ?? "");
  const enrolledPatients = clientUsers.filter((cu) => !!cu.enrollmentDate);
  return (
    <Card bg={"white"} w='40%' p={5}>
      <HStack
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={2}
      >
        <HStack>
          <LuUsers />
          <Heading as={"h2"} fontSize={"lg"}>
            Total number of enrolled patients
          </Heading>
        </HStack>

        <Heading as={"h2"} fontSize={"xl"}>
          {enrolledPatients.length ?? 0}
        </Heading>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuInbox />
          <Text fontWeight={"normal"}>Need to be enrolled</Text>
        </HStack>
        <Text>
          {clientUsers.length - enrolledPatients.length > 0
            ? clientUsers.length - enrolledPatients.length
            : 0}
        </Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuUserPlus />
          <Text fontWeight={"normal"}>Approved for CHW Benefits</Text>
        </HStack>

        <Text>
          {/**TODO: fix this to be CHW benefit approved */}
          {enrolledPatients.filter((p) => p.isTreatedForHypertension).length ??
            0}
        </Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuDownload />
          <Text fontWeight={"normal"}>Downloaded App</Text>
        </HStack>
        <Text>
          {/**TODO: fix this to be app download date??? */}
          {enrolledPatients.filter((p) => p.onboardingDate).length ?? 0}
        </Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuActivity />
          <Text fontWeight={"normal"}>Actively Engaged</Text>
        </HStack>

        <Text>0</Text>
      </HStack>
    </Card>
  );
};

export default DashboardEnrolledPatients;
