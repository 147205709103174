import { useCallback, useEffect, useState } from "react";
import { PrimaryCareProv } from "@oben-core-web/models/primary-care-prov";
import { PrimaryCareProvService } from "@oben-core-web/services/primary-care-prov-service";

const usePrimaryCareProvs = () => {
  const [primaryCareProvs, setPrimaryCareProvs] = useState<PrimaryCareProv[]>();

  const fetchPrimaryCareProvs = useCallback(async () => {
    const primaryCareProvService = new PrimaryCareProvService();
    const careProvs = await primaryCareProvService.getAllPrimaryCareProvs();
    setPrimaryCareProvs(careProvs);
  }, []);

  useEffect(() => {
    fetchPrimaryCareProvs();
  }, [fetchPrimaryCareProvs]);

  return { primaryCareProvs, fetchPrimaryCareProvs };
};

export default usePrimaryCareProvs;
