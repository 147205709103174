import useClientUserSubscription from "@/hooks/useClientUserSubscription";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  IconButton,
  useToast,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  Select,
  VStack
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
// import { Select } from "chakra-react-select";
import { ClientUser, IClientUserData } from "@oben-core-web/models/client-user";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import useBarbershops from "@/hooks/useBarbershops";
import useBarbers from "@/hooks/useBarbers";
import usePayers from "@/hooks/usePayers";
// import { UserName } from "@oben-core-web/models/user-name";
// import { AppInfo } from "@oben-core-web/models/app-info";
// import { SystemInfo } from "@oben-core-web/models/system-info";
// import { BpReading } from "@oben-core-web/models/bp-reading";
// import { CholesterolReading } from "@oben-core-web/models/cholesterol-reading";
// import { ASCVDRisk } from "@oben-core-web/models/ascvd-risk";
// import { Address } from "@oben-core-web/models/address";

interface IPatientProfileForm {
  clientId: string;
}

const PatientProfileForm = ({ clientId }: IPatientProfileForm) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const { clientUser: patient } = useClientUserSubscription(clientId);
  const { payers } = usePayers();
  const { barbershops } = useBarbershops(patient?.placeBasedCareProvId ?? "");
  const { barbers } = useBarbers(patient?.placeBasedCareProvId ?? "");
  // const [barberOptions, setBarberOptions] = useState<BarberUser[]>([]);
  const { formState, register, reset, handleSubmit, control } = useForm<
    Omit<IClientUserData, "dob"> & { dob: string }
  >({
    defaultValues: {
      ...patient?.toJson(),
      ...(patient?.dob
        ? {
            dob: DateTime.fromJSDate(patient.dob).toUTC().toFormat("yyyy-MM-dd")
          }
        : {})
    },
    mode: "onChange"
  });
  useEffect(() => {
    reset({
      ...patient?.toJson(),
      ...(patient?.dob
        ? {
            dob: DateTime.fromJSDate(patient.dob).toUTC().toFormat("yyyy-MM-dd")
          }
        : {})
    });
  }, [patient, reset]);
  const submissionHandler = async (
    data: Omit<IClientUserData, "dob"> & { dob: string }
  ) => {
    // console.log({ data });
    console.log(formState.isDirty);
    setLoading(true);
    if (formState.isDirty) {
      const clientUserService = new ClientUserService();
      const clientUpdate = ClientUser.fromMap(clientId, {
        ...data,
        dob: DateTime.fromISO(data.dob, { locale: "en-CA" }).toJSDate()
      });
      await clientUserService
        .updateClientUser(clientUpdate)
        .then(() => {
          toast({ status: "success", description: "Patient profile updated" });
          setLoading(false);
          onToggle();
        })
        .catch((e) => {
          console.log(e);
          toast({
            status: "error",
            description: "Failed to update patient profile"
          });
        });
    } else {
      toast({ status: "info", description: "No changes detected" });
      setLoading(false);
    }
  };
  const barberOptions = useMemo(() => {
    if (!barbershops || !barbers) return [];
    if (
      patient?.prefBarbershopId !== undefined &&
      patient.prefBarbershopId !== null
    ) {
      return barbers.filter((b) =>
        b.barbershopIds.includes(patient.prefBarbershopId as string)
      );
    }
    return [];
  }, [barbershops, barbers, patient]);
  // const ethnicityOptions = [
  //   { label: "Eth1", value: "Eth1Val" },
  //   { label: "Eth2", value: "Eth2Val" },
  //   { label: "Eth3", value: "Eth3Val" },
  //   { label: "Eth4", value: "Eth4Val" }
  // ];
  return (
    <>
      <IconButton
        aria-label='Edit-patient-btn'
        variant='ghost'
        icon={<EditIcon />}
        onClick={onToggle}
        alignSelf={"flex-end"}
        position='absolute'
        right={4}
        top={4}
      >
        Edit Patient Profile
      </IconButton>
      <Modal
        isOpen={isOpen}
        onClose={onToggle}
        size={"xl"}
        scrollBehavior={"outside"}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(submissionHandler)}>
            <ModalHeader>Edit Patient Profile</ModalHeader>
            <ModalBody>
              <Tabs>
                <TabList>
                  <Tab>Patient</Tab>
                  <Tab>Contact</Tab>
                  <Tab>Appointment Preferences</Tab>
                  <Tab>Insurance</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4}>
                      <FormControl>
                        <FormLabel>First Name</FormLabel>
                        <Input
                          placeholder='First Name'
                          {...register("name.first")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Last Name</FormLabel>
                        <Input
                          placeholder='Last Name'
                          {...register("name.last")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Display Name</FormLabel>
                        <Input
                          placeholder='Display Name'
                          {...register("name.display")}
                        />
                      </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={2} my={4}>
                      <FormControl>
                        <FormLabel>Date of Birth</FormLabel>
                        <Input type='date' {...register("dob")} />
                      </FormControl>
                    </SimpleGrid>
                    {/* <FormControl my={4}>
                      <FormLabel>Ethnicity</FormLabel>
                      <Select
                        isMulti
                        options={ethnicityOptions}
                        placeholder='Select Ethnicities'
                        closeMenuOnSelect={false}
                        hasStickyGroupHeaders
                      />
                    </FormControl> */}
                  </TabPanel>
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4} my={4}>
                      {/* <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input
                          type='email'
                          placeholder='patient@email.com'
                          {...register("email")}
                        />
                      </FormControl> */}
                      <FormControl>
                        <FormLabel>Phone Number</FormLabel>
                        <Input
                          placeholder={"(123) 456-7890"}
                          {...register("phoneNumber")}
                        />
                      </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={2} spacing={4}>
                      <FormControl>
                        <FormLabel>Street 1</FormLabel>
                        <Input
                          placeholder='123 Fake Street'
                          {...register("address.street1")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Street 2</FormLabel>
                        <Input
                          placeholder='Suite 100'
                          {...register("address.street2")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>City</FormLabel>
                        <Input
                          placeholder='Oakland'
                          {...register("address.city")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>State</FormLabel>
                        <Input
                          placeholder='CA'
                          {...register("address.state")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Zip</FormLabel>
                        <Input
                          placeholder='12345'
                          {...register("address.zip")}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </TabPanel>
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4} my={4}>
                      <FormControl>
                        <FormLabel>Preferred Barbershop</FormLabel>
                        <Controller
                          control={control}
                          name='prefBarbershopId'
                          render={({ field }) => (
                            <Select
                              value={field.value ?? ""}
                              onChange={(e) => {
                                if (!barbers) return;
                                const barbersAtThisShop = barbers?.filter((b) =>
                                  b.barbershopIds.includes(e.target.value)
                                );
                                if (barbersAtThisShop) {
                                  // setBarberOptions(barbersAtThisShop);
                                  field.onChange(e);
                                }
                              }}
                            >
                              {barbershops.map((bs) => (
                                <option
                                  key={`pref-barbershop-${bs.id}`}
                                  value={bs.id}
                                >
                                  {bs.businessName}
                                </option>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <FormControl isDisabled={barberOptions.length === 0}>
                        <FormLabel>Preferred Barber</FormLabel>
                        <Select {...register("prefBarberId")}>
                          {barberOptions.length > 0 ? (
                            barberOptions.map((b) => (
                              <option
                                key={`pref-barber-${b.uid}`}
                                value={b.uid}
                              >
                                {b.name.fullName}
                              </option>
                            ))
                          ) : (
                            <option value={patient?.prefBarberId ?? ""}>
                              {
                                barbers?.find(
                                  (b) => b.uid === patient?.prefBarberId
                                )?.name.fullName
                              }
                            </option>
                          )}
                        </Select>
                      </FormControl>
                    </SimpleGrid>
                    <FormControl>
                      <SimpleGrid columns={2} spacing={4}>
                        <FormLabel>Preferred Appointment Times</FormLabel>
                        <VStack>
                          <Input
                            {...register("prefApptTimes.0")}
                            placeholder={"Eg: Mondays after 1pm"}
                          />
                          <Input
                            {...register("prefApptTimes.1")}
                            placeholder={"Eg: Mondays after 1pm"}
                          />
                          <Input
                            {...register("prefApptTimes.2")}
                            placeholder={"Eg: Mondays after 1pm"}
                          />
                        </VStack>
                      </SimpleGrid>
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4} my={4}>
                      <FormControl>
                        <FormLabel>Payer</FormLabel>
                        <Controller
                          control={control}
                          name='payerId'
                          render={({ field }) => (
                            <Select {...field} value={field.value ?? ""}>
                              {payers.map((p) => (
                                <option key={`payer-${p.id}`} value={p.id}>
                                  {p.businessName}
                                </option>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Patient Policy Number</FormLabel>
                        <Input {...register("payerPolicyNumber")}></Input>
                      </FormControl>
                    </SimpleGrid>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onToggle} mr={2}>
                Cancel
              </Button>
              <Button type='submit' isDisabled={!formState.isDirty || loading}>
                {loading ? <Spinner /> : "Save"}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PatientProfileForm;
