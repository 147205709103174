import { useCallback, useEffect, useRef, useState } from "react";
import usePatients from "./usePatients";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import { BpReading } from "@oben-core-web/models/bp-reading";
import { groupBy } from "lodash";
import { BpGoal } from "@oben-core-web/models/bp-goal";

const usePharmacistQueries = (placeBasedCareProvId: string) => {
  const fetchedOnMount = useRef<boolean>(false);
  const [patientsAtGoalCount, setPatientsAtGoalCount] = useState(0);
  const { clientUsers } = usePatients(placeBasedCareProvId);

  const clientIds = clientUsers.map((cu) => cu.uid);

  const fetchCarePlans = useCallback(async (clientIds: string[]) => {
    const carePlanService = new CarePlanService();
    const carePlans = await carePlanService.getCarePlansForClients(clientIds);
    return carePlans;
  }, []);

  const getPatientsAtGoalCount = useCallback(
    async (clientIds: string[]) => {
      const patientCarePlans = await fetchCarePlans(clientIds);
      const carePlansByClient = groupBy(patientCarePlans, "clientId");

      const dataByClient = clientUsers.reduce((a, c) => {
        const clientCarePlan = carePlansByClient[c.uid]?.find(
          (cp) => cp.modelId === c.currentCarePlanId
        );
        a[c.uid] = {
          lastBPReading: c.currentBpReading,
          currentBPGoal:
            clientCarePlan?.bpGoals[clientCarePlan.bpGoals.length - 1] ?? null
        };
        return a;
      }, {} as Record<string, { lastBPReading: BpReading | null; currentBPGoal: BpGoal | null }>);

      let patientsAtGoalCount = 0;

      for (const clientId in dataByClient) {
        const { lastBPReading, currentBPGoal } = dataByClient[clientId];
        if (lastBPReading && currentBPGoal) {
          if (
            lastBPReading.systolic <= currentBPGoal.systolic &&
            lastBPReading.diastolic <= currentBPGoal.diastolic
          ) {
            patientsAtGoalCount++;
          }
        }
      }
      setPatientsAtGoalCount(patientsAtGoalCount);
      return patientsAtGoalCount;
    },
    [fetchCarePlans, clientUsers]
  );

  useEffect(() => {
    if (clientIds.length > 0 && !fetchedOnMount.current) {
      fetchedOnMount.current = true;
      getPatientsAtGoalCount(clientIds);
    }
  }, [getPatientsAtGoalCount, clientIds]);

  return { countOfPatientsAtBPGoal: patientsAtGoalCount };
};

export default usePharmacistQueries;
