import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  // addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
  setDoc
} from "firebase/firestore";
import { Database } from "../../firebase";

import { CarePlan } from "../models/care-plan";
import { GaLog } from "../utils/ga-log";

export class CarePlanService {
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._parentCollectionName = "clients";
    this._collectionName = "carePlans";
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get all care plans notes for a specified client
  async getClientCarePlans(clientId: string): Promise<CarePlan[]> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${clientId}/${this._collectionName}`
    );
    try {
      const plansQuery = query(subcollectionRef);
      const qSnapshot = await getDocs(plansQuery);
      GaLog.readCollection(subcollectionRef.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) =>
        CarePlan.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(subcollectionRef.path, error);
      throw error;
    }
  }

  async getCarePlan(carePlanId: string): Promise<CarePlan> {
    try {
      const planQuery = query(
        this._collectionGroup,
        where("modelId", "==", carePlanId)
      );
      const qSnapshot = await getDocs(planQuery);
      if (qSnapshot.empty) {
        throw new Error(`CarePlan not found: ${carePlanId}`);
      }
      const plan = CarePlan.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(
        `${this._parentCollectionName}/${plan.clientId}/${this._collectionName}`,
        plan.modelId
      );
      return plan;
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  async addCarePlan(carePlan: CarePlan): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${carePlan.clientId}/${this._collectionName}`
    );
    try {
      const cpDocRef = doc(subcollectionRef);
      await setDoc(cpDocRef, { ...carePlan.toJson(), modelId: cpDocRef.id });
      // const docRef = await addDoc(subcollectionRef, carePlan.toJson());
      // GaLog.addDocument(subcollectionRef.path, docRef.id);
      // return docRef.id;
      GaLog.addDocument(subcollectionRef.path, cpDocRef.id);
      return cpDocRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateCarePlan(carePlan: CarePlan): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${carePlan.clientId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, carePlan.modelId);
      await updateDoc(docRef, carePlan.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteCarePlan(carePlan: CarePlan): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${carePlan.clientId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, carePlan.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, carePlan.modelId);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }

  async getCarePlansForClients(clientIds: string[]): Promise<CarePlan[]> {
    try {
      const planQuery = query(
        this._collectionGroup,
        where("clientId", "in", clientIds)
      );
      const qSnapshot = await getDocs(planQuery);
      const plans = qSnapshot.docs.map((doc) => CarePlan.fromFirestore(doc));

      GaLog.readCollection(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        qSnapshot.size
      );
      return plans;
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }
}
